export default request => ({
  enableSentence(articleId, sentenceOrder) {
    return request({
      path: `articles/${articleId}/sentences/${sentenceOrder}/enable`,
      method: 'patch'
    })
  },
  disableSentence(articleId, sentenceOrder) {
    return request({
      path: `articles/${articleId}/sentences/${sentenceOrder}/disable`,
      method: 'patch'
    })
  },
  postRemark(articleId, sentenceOrder, remark) {
    return request({
      path: `articles/${articleId}/sentences/${sentenceOrder}/remark`,
      method: 'patch',
      body: remark
    })
  },
  postGuideSentenceRemark(articleId, guideId, sentenceOrder, remark) {
    return request({
      path: `articles/${articleId}/guides/${guideId}/sentences/${sentenceOrder}/remark`,
      method: 'patch',
      body: remark
    })
  }
})
