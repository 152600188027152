import { toast } from "react-toastify";
import * as logger from "../../helpers/logger";
import api from "../../api";
import errorHandler from "./errorHandler";
import * as constants from "../../constants";
import { showLoader, hideLoader } from "./loader";
import { setAudio, play, stop, reset } from "./player";
import * as util from "../../helpers/util";
import {getCurrentSentences, isArticleSentence} from "../../helpers/entityHelper";
import {getRecordings} from "./article";
import {getGuideRecordings} from "./guides";

const _scrollToSentenceInList = (sentence) => {
	const div = document.querySelector(
		`#${constants.APP_CONTAINER_NAME} #navigation-scroll`
	);
	const tdIndex = Math.max(sentence.Order * 2 - 1, 0);
	const doc = div.getElementsByTagName("td")[tdIndex];
	div.scrollTop = doc.offsetTop;
};

export const onAudioEnded = () => {
	return (dispatch, getState) => {
		const { player } = getState();
		dispatch(reset());
		if (player.status === constants.PLAYER_STATUS.AutoPlaying) {
			dispatch(selectNextSentence());
		} else {
			dispatch(stop());
		}
	};
};

export const selectNextSentence = () => {
	return (dispatch, getState) => {
		const { sentence, article } = getState();
		const sentences = getCurrentSentences(article, sentence);
		for (
			let order = sentence.Order + 1;
			order < sentences.length;
			order++
		) {
			const testSentence = sentences[order];
			if (testSentence.SentenceState === constants.SENTENCE_STATE.Recorded) {
				return dispatch(setSelectedSentence(testSentence));
			}
		}

		dispatch(stop());
	};
};

export const selectFirstRelevantSentence = () => {
	return (dispatch, getState) => {
		const { article } = getState();
		if (article && article.SentencesCurrentVersion) {
			let sentence;

			sentence = article.SentencesCurrentVersion.find((s) => {
				return s.State !== constants.SENTENCE_STATE.Disabled;
			});

			if (article.State === constants.ARTICLE_STATE.CorrectionsNeeded) {
				const firstSentenceWithRemark = article.SentencesCurrentVersion.find(
					(s) => {
						return (
							s.State !== constants.SENTENCE_STATE.Disabled &&
							!util.isEmptyOrSpaces(s.Remark)
						);
					}
				);
				if (firstSentenceWithRemark) {
					sentence = firstSentenceWithRemark;
				}
			}

			logger.debug("Selecting first sentence", sentence);
			if (sentence) {
				dispatch(setSelectedSentence(sentence));
			}
		}
	};
};

export const selectFirstRelevantGuideSentence = (guide) => {
	return (dispatch) => {
		if (guide && guide.GuideSentences) {
			let sentence;
			sentence = guide.GuideSentences.find((s) => {
				return s.State !== constants.SENTENCE_STATE.Disabled;
			});

			if (guide.State === constants.GUIDE_STATE.CorrectionsNeeded) {
				const firstSentenceWithRemark = guide.GuideSentences.find(
					(s) => {
						return (
							s.State !== constants.SENTENCE_STATE.Disabled &&
							!util.isEmptyOrSpaces(s.Remark)
						);
					}
				);
				if (firstSentenceWithRemark) {
					sentence = firstSentenceWithRemark;
				}
			}

			logger.debug("Selecting first guide sentence", sentence);
			if (sentence) {
				dispatch(setSelectedSentence(sentence));
			}
		}
	};
};

export const setSelectedSentence = (sentence, noscroll, cb) => {
	return (dispatch, getState) => {
		const { player } = getState();
		if (player.status === constants.PLAYER_STATUS.Recording) {
			return toast.error(
				"Please stop the recording before selecting a new sentence"
			);
		}
		dispatch({
			type: "SET_SELECTED_SENTENCE",
			sentence
		});

		dispatch(setSelectedAudio());
		if (!noscroll) {
			_scrollToSentenceInList(sentence);
		}

		if (typeof cb === "function") cb(sentence);
		return sentence;
	};
};

export const saveRemark = (sentence, remark) => {
	return (dispatch, getState) => {
		if(sentence.ArticleId){
			// Article case.
			return api.sentences
				.postRemark(sentence.ArticleId, sentence.Order, remark)
				.then((data) => {
					dispatch({
						type: "SAVE_REMARK",
						sentenceId: sentence.Order,
						remark
					});
					return data;
				});
		}

		const { article } = getState();
		// Guide case.
		return api.sentences
			.postGuideSentenceRemark(article.Id, sentence.GuideId, sentence.Order, remark)
			.then((data) => {
				dispatch({
					type: "SAVE_GUIDE_SENTENCE_REMARK",
					sentenceId: sentence.Order,
					GuideId: sentence.GuideId,
					remark
				});
				return data;
			});
	};
};

export const setSelectedAudio = () => {
	return async (dispatch, getState) => {
		dispatch({
			type: "SET_SELECTED_AUDIO_REQUEST"
		});
		const {sentence, player} = getState();
		let soundToPlay = player?.recordings[sentence.RecordingId];
		if (soundToPlay && soundToPlay.Url) {

			const timeLeftSeconds = soundToPlay.TimeLeftSeconds || -1;
			const secondsPassedSinceLastFetch = Math.ceil(
				(new Date() - player.latestFetch) / 1000
			);
			if (secondsPassedSinceLastFetch >= timeLeftSeconds) {
				if (isArticleSentence(sentence)) {
					soundToPlay =  await dispatch(fetchSelectedArticleAudio());
				} else {
					// Guides case.
					soundToPlay =  await dispatch(fetchSelectedGuideAudio())
				}
			}
			dispatch(setAudio(soundToPlay.Url));

			dispatch({
				type: "SET_SELECTED_AUDIO_SUCCESS"
			});
			if (
				player.status === constants.PLAYER_STATUS.Playing ||
				player.status === constants.PLAYER_STATUS.AutoPlaying
			) {
				dispatch(play());
			}
			dispatch(hideLoader("setSelectedAudio"));
		} else {
			dispatch(stop());
			dispatch(reset());
			dispatch(hideLoader("setSelectedAudio"));
		}
	}
};

export const fetchSelectedArticleAudio = () => {
	return (dispatch, getState) => {
		const {sentence} = getState();

		dispatch(showLoader("setSelectedAudio"));
		return dispatch(getRecordings(sentence.ArticleId))
			.then((data) => {
			dispatch(setAudio(data[sentence.RecordingId].Url));
			return data[sentence.RecordingId];
		})
			.catch((err) => {
				logger.error(err, "Failed to get audio");
			});
	}
};

export const fetchSelectedGuideAudio = () => {
	return (dispatch, getState) => {
		const {article, sentence} = getState();

		dispatch(showLoader("setSelectedAudio"));
		return dispatch(getGuideRecordings(article.Id, sentence.GuideId))
			.then((data) => {
				return data[sentence.RecordingId];
			})
			.catch((err) => {
				logger.error(err, "Failed to get audio");
			});
	}
};

export const toggleDeactivated = (cb) => {
	return (dispatch, getState) => {
		const { sentence } = getState();
		if (sentence.SentenceState === constants.SENTENCE_STATE.Disabled) {
			api.sentences
				.enableSentence(sentence.ArticleId, sentence.Order)
				.then((data) => {
					dispatch({
						type: "ENABLE_SENTENCE",
						sentence
					});
					return data;
				})
				.then(() => {
					if (typeof cb === "function") cb(sentence);
				})
				.catch((err) => {
					logger.error(err, "Failed to enable sentence");
				});
		} else {
			api.sentences
				.disableSentence(sentence.ArticleId, sentence.Order)
				.then((data) => {
					return dispatch({
						type: "DISABLE_SENTENCE",
						sentence
					});
				})
				.then(() => {
					if (typeof cb === "function") cb(sentence);
				})
				.catch((err) => {
					logger.error(err, "Failed to disable sentence");
				});
		}
	};
};

export const saveSelectedSound = (
	blob,
	articleId,
	guideId,
	sentenceOrder,
	recordedSeconds
) => {
	return (dispatch, getState) => {
		dispatch({
			type: "POST_SOUND_REQUEST"
		});
		const formData = new FormData();
		formData.append("file", blob);

		const handleError = (error) => {
			logger.error(error, "Failed to save recording!");
			toast.error(
				"Failed to upload recording! See the console for more information."
			);
		}

		if (constants.GUID_EMPTY === guideId) {
			// Article case;
			return api.recordings
				.postRecording(articleId, sentenceOrder, recordedSeconds || 0, formData)
				.then((data) => {
					if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
					dispatch({
						type: "POST_SOUND_SUCCESS",
						data: {
							recordingId: data.Id,
							sentenceId: sentenceOrder,
							Url: URL.createObjectURL(blob)
						}
					});
					return data;
				})
				.catch(handleError);
		}
		// Guide case;
		return api.recordings
			.postGuideRecording(articleId, guideId, sentenceOrder, recordedSeconds || 0, formData)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "POST_GUIDE_SOUND_SUCCESS",
					data: {
						GuideId: data.GuideId,
						recordingId: data.Id,
						sentenceId: sentenceOrder,
						Url: URL.createObjectURL(blob)
					}
				});
				return data;
			})
			.catch((error) => {
				handleError(error)
			});
	};
};
