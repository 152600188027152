import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import RndWrapper from "../rndWrapper";
import { connector } from "../../store";
import { isAuthenticated } from "../../helpers/authHelpers";
import Select from "../buttons/select";
import * as constants from "../../constants";
import * as entityHelper from "../../helpers/entityHelper";
import {getGuideById} from "../../helpers/entityHelper";

class RemarkRnd extends Component {
	constructor(props) {
		super(props);
		document.addEventListener("keydown", this._onKeyDown);
		this.textarea = null;
	}

	componentDidMount() {
		this._setRndDefaults();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { sentence } = this.props;
		if (
			prevProps.sentence.Order !== sentence.Order ||
			prevProps.sentence.Remark !== sentence.Remark
		) {
			this._setCurrentRemark();
		}
	};

	componentWillUnmount() {
		document.removeEventListener("keydown", this._onKeyDown);
	}

	_addDefaultRemark = (newRemark) => {
		this.textarea.value += newRemark + " ";
		this.textarea.focus();
	};

	_setTextArea = (element) => {
		if (element) {
			this.textarea = element;
		}
	};

	_setCurrentRemark = () => {
		const { sentence } = this.props;
		if (sentence) {
			this.textarea.value = sentence.Remark || "";
			this.textarea.blur();
		}
	};

	_shouldShowRemarker = () => {
		const { sentence, user, article } = this.props;
		if (!isAuthenticated(user)) return false;
		if (!article.Id || !(sentence.ArticleId || sentence.GuideId)) return false;
		if (sentence.SentenceState === constants.SENTENCE_STATE.Disabled)
			return false;

		if (entityHelper.isArticleSentence(sentence)) {
			return article.State === constants.ARTICLE_STATE.ProofListening;
		}

		return getGuideById(article, sentence.GuideId).State === constants.GUIDE_STATE.ProofListening;
	};

	_onKeyDown = (e) => {
		//  Ctrl + 0 .. 9
		const { player, action, sentence } = this.props;
		if ((e.ctrlKey || e.metaKey) && constants.REMARKS_PRESETS[e.code]) {
			e.preventDefault();
			if (
				player?.status === constants.PLAYER_STATUS.Playing ||
				player?.status === constants.PLAYER_STATUS.AutoPlaying
			) {
				action.pause();
			}
			this._addDefaultRemark(constants.REMARKS_PRESETS[e.code].value);
		}

		if (this.textarea === document.activeElement && e.code === "Enter") {
			e.preventDefault();
			action.saveRemark(sentence, this.textarea.value);
			this.textarea.blur();
		}
	};

	_setRndDefaults = () => {
		if (window.remarkRnd) {
			window.remarkRnd.loadPropertiesFromCookie();
		}
	};

	render() {
		const { sentence, action } = this.props;
		return (
			<div className="height-100">
				<RndWrapper
					ref={(c) => {
						window.remarkRnd = c;
					}}
					defaultWidth={400}
					defaultHeight={240}
					isVisible={this._shouldShowRemarker()}
					right={400}
					top={250}
					className="remarker"
					title="Edit remark"
					header={<div className="height-100" />}
					content={
						<div className="height-100">
							<div className="remark-input">
								<Select
									values={Object.entries(constants.REMARKS_PRESETS).map(
										(p) => p[1]
									)}
									inputProps={this.props}
									onChange={this._addDefaultRemark}
								/>

								<div className="remark">
									{sentence && (
										<textarea
											ref={this._setTextArea}
											className="remark__text"
										/>
									)}
								</div>
							</div>
						</div>
					}
					footer={
						<div className="height-100">
							<div style={{ float: "right" }}>
								<button
									className="btn btn-block btn-primary"
									type="button"
									onClick={() =>
										action.saveRemark(sentence, this.textarea.value)
									}
								>
									Save
								</button>
							</div>
							<div style={{ float: "right", marginRight: "10px" }}>
								<button
									className="btn btn-block btn-danger"
									type="button"
									onClick={() => action.saveRemark(sentence, "")}
								>
									Remove
								</button>
							</div>
						</div>
					}
				/>
			</div>
		);
	}
}

RemarkRnd.propTypes = {
	sentence: PropTypes.object.isRequired,
	article: PropTypes.object.isRequired,
	player: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	action: PropTypes.shape({
		saveRemark: PropTypes.func.isRequired,
		pause: PropTypes.func.isRequired,
		play: PropTypes.func.isRequired
	}).isRequired
};

export default connector(RemarkRnd, (props) => ({
	article: props.article,
	sentence: props.sentence,
	user: props.user,
	player: props.player,
	action: {
		saveRemark: props.action.saveRemark,
		pause: props.action.pause,
		play: props.action.play
	}
}));
