const userReducer = (state = {}, action) => {
	switch (action.type) {
		case "SET_USER":
			return action.data || state;
		case "LOGOUT":
			return {};
		case "ARTICLE_DONE":
		case "GUIDE_DONE":
			return {
				...state,
				groups: []
			};
		case "CLEAR_DATA":
			return {};
		default:
			return state;
	}
};
export default userReducer;
