import PropTypes from "prop-types";
import { RecordRTCPromisesHandler } from "recordrtc";
import remix from "audio-buffer-remix";
import * as logger from "../helpers/logger";
import audioBufferToWav from "../helpers/audioHelpers";

export const RECORDER_STATUS = Object.freeze({
	Undefined: "Undefined",
	Stopped: "Stopped",
	Recording: "Recording",
	Paused: "Paused"
});

export const RECORDER_OPTIONS = Object.freeze({
	MimeType: "audio/webm"
});

export default class RtcMediaRecorder {
	constructor(
		onRecordStart,
		onRecordStop,
		onRecordPause,
		onRecordResume,
		onRecording,
		onError,
		audioConstraints
	) {
		this.mediaRecorder = null;
		this.recordingTimer = null;
		this.totalRecordedSeconds = 0;
		this.state = RECORDER_STATUS.Stopped;
		this.onRecordStartCallback = onRecordStart || null;
		this.onRecordStopCallback = onRecordStop || null;
		this.onRecordPauseCallback = onRecordPause || null;
		this.onRecordResumeCallback = onRecordResume || null;
		this.onRecordingCallback = onRecording || null;
		this.onErrorCallback = onError || null;
		this.SetAudioConstraints(audioConstraints);
		this._init();
	}

	_init = () => {};

	SetAudioConstraints = (audioConstraints) => {
		logger.debug(
			"setting audioConstraints ",
			audioConstraints,
			"rtcMediaRecorder"
		);
		this.audioConstraints = audioConstraints || true;
	};

	_startRecordingTimer = () => {
		this._stopRecordingTimer();
		this.totalRecordedSeconds = 0;
		this.recordingTimer = setInterval(this._onRecording, 1000);
	};

	_stopRecordingTimer = () => {
		if (this.recordingTimer) {
			clearInterval(this.recordingTimer);
		}
	};

	_onRecording = () => {
		if (this.IsRecording()) {
			++this.totalRecordedSeconds;
			if (typeof this.onRecordingCallback === "function") {
				this.onRecordingCallback(this.totalRecordedSeconds);
			}
		}
	};

	_onError = (message, error) => {
		if (typeof this.onErrorCallback === "function") {
			this.onErrorCallback("(rtcMediaRecorder) " + message, error);
		}
	};

	_onRecordStart = (args) => {
		navigator.mediaDevices
			.getUserMedia({
				audio: this.audioConstraints,
				video: false
			})
			.then((stream) => {
				// Initialize the recorder
				this.mediaRecorder = new RecordRTCPromisesHandler(stream, {
					mimeType: RECORDER_OPTIONS.MimeType,
					type: "audio"
				});

				// Start recording
				this.mediaRecorder
					.startRecording()
					.then(() => {
						this._setState(RECORDER_STATUS.Recording);
						this._startRecordingTimer();
						logger.debug(
							"onRecordStartCallback ",
							this.onRecordStartCallback ? "callback set" : "callback not set",
							"rtcMediaRecorder"
						);

						if (typeof this.onRecordStartCallback === "function") {
							this.onRecordStartCallback(args);
						}
						return null;
					})
					.catch((error) => {
						this._onError(
							"Failed to start recording! Make sure the browser has access to your microphone.",
							error
						);
					});

				// release stream on stopRecording
				this.mediaRecorder.stream = stream;
				return null;
			})
			.catch((error) => {
				this._onError(
					"Cannot access media devices! Make sure the browser has access to your microphone.",
					error
				);
			});
	};

	_onRecordStop = (args) => {
		if (!this.mediaRecorder) {
			logger.warn("Mediarecorder is not set");
			return;
		}

		if (this.state === RECORDER_STATUS.Paused) {
			this._onRecordResume();
		}

		this.mediaRecorder
			.getState()
			.then((state) => {
				if (state !== "recording") {
					logger.warn("State is not recording!", state);
					throw new Error("State is not recording!");
				}
				return this.mediaRecorder.stopRecording();
			})
			.then(() => {
				return this.mediaRecorder.getBlob();
			})
			.then((blob) => {
				// Stop the device streaming
				this.mediaRecorder.stream.stop();
				this.mediaRecorder.recordRTC.destroy();

				if (
					blob === null ||
					typeof blob === "undefined" ||
					typeof blob.size === "undefined" ||
					blob.size === 0
				) {
					this._onError("Recording failed! Recorded file size is zero.");
					return null;
				}
				logger.debug("Blob size", blob.size, blob);

				return this._convertToMono(blob);
			})
			.catch((error) => {
				this._onError(
					"Recording failed! Make sure the browser has access to your microphone.",
					error
				);
				return null;
			})
			.then((blobWrapper) => {
				this._stopRecordingTimer();
				this._setState(RECORDER_STATUS.Stopped);

				logger.debug(
					"onRecordStopCallback ",
					this.onRecordStopCallback ? "callback set" : "callback not set",
					"rtcMediaRecorder"
				);
				if (typeof this.onRecordStopCallback === "function") {
					this.onRecordStopCallback(blobWrapper, args);
				}
				return null;
			})
			.catch((error) => {
				this._onError(
					"Recording failed! Failed to store recording data.",
					error
				);
				return null;
			});
	};

	_onRecordPause = (args) => {
		if (!this.mediaRecorder) {
			return;
		}

		this.mediaRecorder.recordRTC.pauseRecording();
		this._setState(RECORDER_STATUS.Paused);

		logger.debug(
			"onRecordPauseCallback ",
			this.onRecordPauseCallback ? "callback set" : "callback not set",
			"rtcMediaRecorder"
		);
		if (typeof this.onRecordPauseCallback === "function") {
			this.onRecordPauseCallback(args);
		}
	};

	_onRecordResume = (args) => {
		this.mediaRecorder.recordRTC.resumeRecording();
		this._setState(RECORDER_STATUS.Recording);
		logger.debug(
			"onRecordResumeCallback ",
			this.onRecordResumeCallback ? "callback set" : "callback not set",
			"rtcMediaRecorder"
		);
		if (typeof this.onRecordResumeCallback === "function") {
			this.onRecordResumeCallback(args);
		}
	};

	_setState = (newState) => {
		logger.debug(
			"State: " + this.state + " => " + newState,
			"rtcMediaRecorder"
		);
		this.state = newState;
	};

	_convertToMono = (blob) => {
		const audioContext = new AudioContext();
		const fileReader = new FileReader();

		return new Promise((resolve, reject) => {

			// Set up file reader on loaded end event
			fileReader.onloadend = async () => {
				/** @type {ArrayBuffer} */
				const arrayBuffer = fileReader.result;

				if (typeof arrayBuffer === "string") {
					reject(new Error('Recording failed. Failed to read array buffer.'));
				}

				// Convert array buffer into audio buffer
				await audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {

					if (audioBuffer.numberOfChannels === 1) {
						resolve(new Blob([blob], {
							type: RECORDER_OPTIONS.MimeType
						}));
					}

					const mixedBuffer = remix(audioBuffer, 1);

					const data = audioBufferToWav(mixedBuffer);
					resolve(new Blob([data.view], {
						type: RECORDER_OPTIONS.MimeType
					}));
				}, () => {
					reject(new Error('Recording failed. Failed to decode array buffer.'));
				});
			}

			// Load blob
			fileReader.readAsArrayBuffer(blob)
		});
	}

	IsRecording = () => {
		return this.state === RECORDER_STATUS.Recording;
	};

	Start = (args) => {
		this._onRecordStart(args);
	};

	Stop = (args) => {
		setTimeout(() => {
			this._onRecordStop(args);
		}, 100);
	};

	Pause = (args) => {
		this._onRecordPause(args);
	};

	Resume = (args) => {
		this._onRecordResume(args);
	};

	GetRecordedSeconds = () => {
		return this.totalRecordedSeconds;
	};

	Destroy = () => {
		this._stopRecordingTimer();
		if (this.state === RECORDER_STATUS.Recording && this.mediaRecorder) {
			this.mediaRecorder
				.stopRecording()
				.then(() => {
					this.mediaRecorder.stream.stop();
					this.mediaRecorder.recordRTC.destroy();
					this._setState(RECORDER_STATUS.Stopped);
					return null;
				})
				.catch((error) => {
					this._onError("Failed to stop recording", error);
				});
		}
	};
}

RtcMediaRecorder.propTypes = {
	onRecordStart: PropTypes.func,
	onRecordStop: PropTypes.func,
	onRecordPause: PropTypes.func,
	onRecordResume: PropTypes.func,
	onRecording: PropTypes.func,
	onError: PropTypes.func,
	audioConstraints: PropTypes.object.isRequired
};
