/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import "./index.css";

export default function SettingsButton(props) {
	const { className } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("button fa fa-cog", className)}
			aria-label="Settings"
			onClick={() => props.onClick()}
		/>
	);
}
