export default function errorHandler(dispatch, data = {}) {
	if (!data || !data.errors) {
		dispatch({
			type: "CLEAR_ERRORS",
			errors: null
		});
		return false;
	}

	dispatch({
		type: "SET_ERRORS",
		errors: data.errors
	});
	return true;
}
