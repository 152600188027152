import * as constants from "../../constants";

const articleReducer = (state = {}, action) => {
	switch (action.type) {
		case "SET_ARTICLE":
			return action.article || state;
		case "POST_SOUND_SUCCESS":
			return {
				...state,
				SentencesCurrentVersion: state.SentencesCurrentVersion.map(sentence =>
					sentence.Order === action.data.sentenceId
						? {
								...sentence,
								RecordingId: action.data.recordingId,
								SentenceState: constants.SENTENCE_STATE.Recorded,
								Remark: "",
								RemarkUpdated: new Date(),
								_state: constants.SENTENCE_STATE.Recorded
						  }
						: sentence
				)
			};
		case "POST_GUIDE_SOUND_SUCCESS":
			return {
				...state,
				Guides: state.Guides.map(guide =>
					guide.Id === action.data.GuideId
						? {
							...guide,
							GuideSentences: guide.GuideSentences.map(
								sentence =>
									sentence.Order === action.data.sentenceId
										? {
											...sentence,
											RecordingId: action.data.recordingId,
											SentenceState: constants.SENTENCE_STATE.Recorded,
											Remark: "",
											RemarkUpdated: new Date(),
											_state: constants.SENTENCE_STATE.Recorded
										}
										: sentence
							)
						}
						: guide
				)
			};
		case "SAVE_REMARK":
			return {
				...state,
				SentencesCurrentVersion: state.SentencesCurrentVersion.map(sentence =>
					sentence.Order === action.sentenceId
						? { ...sentence, Remark: action.remark }
						: sentence
				)
			};
		case "SAVE_GUIDE_SENTENCE_REMARK":
			return {
				...state,
				Guides: state.Guides.map(guide =>
					guide.Id === action.GuideId
						? {
							...guide,
							GuideSentences: guide.GuideSentences.map(
								sentence =>
									sentence.Order === action.sentenceId
										? {
											...sentence,
											Remark: action.remark,
										}
										: sentence
							)
						}
						: guide
				)
			};
		case "SAVE_MANUSCRIPT":
			return {
				...state,
				Guides: state.Guides.map(guide =>
					guide.Id === action.guideId
						? { ...guide, Content: action.manuscript }
						: guide
				)
			};
		case "ENABLE_SENTENCE":
			const isRecorded = action.sentence.RecordingId !== constants.GUID_EMPTY;
			return {
				...state,
				SentencesCurrentVersion: state.SentencesCurrentVersion.map(sentence =>
					sentence.Order === action.sentence.Order
						? {
								...sentence,
								SentenceState: isRecorded
									? constants.SENTENCE_STATE.Recorded
									: constants.SENTENCE_STATE.NotRecorded,
								_state: isRecorded
									? constants.SENTENCE_STATE.Recorded
									: constants.SENTENCE_STATE.NotRecorded
						  }
						: sentence
				)
			};
		case "DISABLE_SENTENCE":
			return {
				...state,
				SentencesCurrentVersion: state.SentencesCurrentVersion.map(sentence =>
					sentence.Order === action.sentence.Order
						? {
								...sentence,
								SentenceState: constants.SENTENCE_STATE.Disabled,
								_state: constants.SENTENCE_STATE.Disabled
						  }
						: sentence
				)
			};

		case "SET_GUIDE":
			return {
				...state,
				Guides: state.Guides.map(guide =>
					guide.Id === action.guide.Id
						? action.guide
						: guide
				)
			}

		case "CONFIRM_GUIDE_MANUSCRIPT":
			return {
				...state,
				Guides: state.Guides.filter(guide => guide.Id !== action.guideId)
			}

		case "SET_GUIDES":
			return {
				...state,
				Guides: action.guides.map(guide => {
						guide.ArticleGotChanges = guide.ArticleVersion !== state.Version;
						return guide;
					}
				)
			}

		case "GUIDES_DISABLED":
		case "GUIDES_ENABLED":
			return {
				...state,
				GuidesEnabled: !state.GuidesEnabled
			};

		case "CLEAR_DATA":
			return {};
		case "SET_URL":
			return {};
		default:
			return state;
	}
};
export default articleReducer;
