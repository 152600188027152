/* eslint-disable prefer-promise-reject-errors */
import * as constants from "../../constants";
import { hideLoader } from "./loader";
import * as logger from "../../helpers/logger";
import * as cookies from "../../helpers/cookies";

let audioInstance;
export const initAudio = (audio = new Audio()) => {
	audioInstance = audio;

	const playbackRate = cookies.hasCookie("player.playback_rate")
		? parseFloat(cookies.getCookie("player.playback_rate"))
		: constants.PLAYBACK_RATE.default;
	audioInstance.setPlaybackRate(playbackRate);

	return dispatch => {
		dispatch({
			type: "INIT_AUDIO"
		});
	};
};

export const stop = () => {
	return dispatch => {
		dispatch({
			type: "PAUSE"
		});
		audioInstance.stop();
	};
};

export const pause = () => {
	return (dispatch, getState) => {
		dispatch({
			type: "PAUSE"
		});
		audioInstance.pause();
	};
};

export const setAudio = url => {
	return (dispatch, getState) => {
		if (!audioInstance) {
			logger.warn("setAudio() audioInstance is null");
			return;
		}
		audioInstance.load(url);
	};
};

export const reset = () => {
	return (dispatch, getState) => {
		dispatch({ type: "RESET_PLAYER" });
		if (audioInstance) {
			audioInstance.pause();
			audioInstance.reload();
		}
	};
};

export const changePlaybackRateUp = () => {
	return dispatch => {
		if (audioInstance.playbackRate < constants.PLAYBACK_RATE.max) {
			audioInstance.setPlaybackRate(
				audioInstance.playbackRate + constants.PLAYBACK_RATE.step
			);
			dispatch({
				type: "CHANGE_PLAYBACK_RATE",
				data: {
					playbackRate: audioInstance.playbackRate
				}
			});
		}
	};
};
export const changePlaybackRateDown = () => {
	return dispatch => {
		if (audioInstance.playbackRate > constants.PLAYBACK_RATE.min) {
			audioInstance.setPlaybackRate(
				audioInstance.playbackRate - constants.PLAYBACK_RATE.step
			);
			dispatch({
				type: "CHANGE_PLAYBACK_RATE",
				data: {
					playbackRate: audioInstance.playbackRate
				}
			});
		}
	};
};
export const changePlaybackRateToggle = () => {
	return dispatch => {
		if (audioInstance.playbackRate >= constants.PLAYBACK_RATE.max) {
			audioInstance.setPlaybackRate(constants.PLAYBACK_RATE.min);
		} else {
			audioInstance.setPlaybackRate(
				audioInstance.playbackRate + constants.PLAYBACK_RATE.step
			);
		}
		dispatch({
			type: "CHANGE_PLAYBACK_RATE",
			data: {
				playbackRate: audioInstance.playbackRate
			}
		});
	};
};
export const play = () => {
	return (dispatch, getState) => {
		const { player, sentence } = getState();
		const soundToPlay = player.recordings[sentence.RecordingId];
		if (
			soundToPlay &&
			soundToPlay.Url &&
			sentence.SentenceState === constants.SENTENCE_STATE.Recorded
		) {
			if (player.autoPlay) {
				dispatch({
					type: "AUTO_PLAY"
				});
			} else {
				dispatch({
					type: "PLAY"
				});
			}
			audioInstance.play();
		} else {
			dispatch(stop());
			dispatch(reset());
		}
	};
};

export const startRecording = () => {
	audioInstance.startRecording();
	return dispatch => {
		dispatch(hideLoader("startRecording"));
		dispatch({
			type: "SET_RECORDING_STATUS",
			data: {
				recording: true,
				isRecordingPaused: false
			}
		});
	};
};

export const stopRecording = () => {
	audioInstance.stopRecording();
	return dispatch => {
		dispatch({
			type: "SET_RECORDING_STATUS",
			data: {
				recording: false,
				isRecordingPaused: false
			}
		});
	};
};

export const pauseRecording = () => {
	return dispatch => {
		dispatch({
			type: "SET_RECORDING_STATUS",
			data: {
				recording: true,
				isRecordingPaused: true
			}
		});
	};
};

export const resumeRecording = () => {
	return dispatch => {
		dispatch({
			type: "SET_RECORDING_STATUS",
			data: {
				recording: true,
				isRecordingPaused: false
			}
		});
	};
};

export const toggleAutoPlay = () => {
	return (dispatch, getState) => {
		const { player } = getState();
		cookies.setCookie("player.auto_play", player.autoPlay ? "0" : "1");
		dispatch({
			type: "TOGGLE_AUTO"
		});
		if (player.status === constants.PLAYER_STATUS.AutoPlaying) {
			dispatch({ type: "PLAY" });
		}
		if (player.status === constants.PLAYER_STATUS.Playing) {
			dispatch({ type: "AUTO_PLAY" });
		}
	};
};
