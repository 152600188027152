import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "./actions";
import createStore from "./createStore";
import SubscribeComponent from "./SubscribeComponent";

const initialState = {};
const store = createStore(initialState);

const mapStateToProps = (state, buildProps) => {
	const {
		user,
		article,
		sentence = {},
		player = {},
		action = {},
		loading = {},
		location = {}
	} = state;

	return buildProps({
		user,
		article,
		sentence,
		player,
		action,
		loading,
		location
	});
};

const mapDispatchToProps = (dispatch, buildProps) => {
	return {
		action: bindActionCreators(
			buildProps({ action: actions }).action || {},
			dispatch
		)
	};
};

export const connector = (component, buildProps = state => state) => {
	return connect(
		state => mapStateToProps(state, buildProps),
		dispatch => mapDispatchToProps(dispatch, buildProps)
	)(SubscribeComponent(component));
};

export default store;
