import articles from "./articles";
import guides from "./guides";
import sentences from "./sentences";
import recordings from "./recordings";
import Requestor from "./requestor";

const jsonContentType = "application/vnd.api+json";

const request = Requestor({
	apiUrl: process.env.REACT_APP_ILT_API_URL || null,
	heads: {
		Accept: jsonContentType,
		"Content-Type": jsonContentType
	}
});

const fileRequest = Requestor({
	apiUrl: process.env.REACT_APP_ILT_API_URL || null,
	heads: {
		Accept: jsonContentType
	}
});

const api = {
	articles: articles(request),
	sentences: sentences(request),
	recordings: recordings(fileRequest),
	guides: guides(request)
};
export default api;
