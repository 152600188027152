import PropTypes from "prop-types";
import Mark from "mark.js";
import { toast } from "react-toastify";
import * as logger from "../helpers/logger";

export const MARKER_OPTIONS = Object.freeze({
	ElementName: "ilt-dtmn-sentence-mark",
	ApplicationContainer: "#dtmn-application"
});

const DOM_NODE_WAIT_TIMEOUT = 8000;

export default class SentenceMarker {
	constructor(domContainerQuery, sentences, backgroundColor, color) {
		logger.debug(
			"Sentencemarker init",
			sentences,
			domContainerQuery,
			backgroundColor,
			color
		);
		this._domContainerQuery = domContainerQuery || "body";
		this._sentences = sentences;
		this._markInstance = null;
		this._backgroundColor = backgroundColor || "blue";
		this._color = color || "white";
		this._missingSentences = [];
		this._lastMarkedAll = null;

		this._initTargetNode();

		if (!this._targetNode) {
			logger.warn(
				"Target DOM container not found, waiting for it to be created..."
			);
			this._waitForTargetNode();
		} else {
			logger.debug("Target DOM container found");
		}
		setTimeout(() => {
			if (!document.querySelector(this._domContainerQuery)) {
				logger.error(
					"Target DOM container '" +
						this._domContainerQuery +
						"' was not found!"
				);
				toast.error(
					"Failed to locate article on page - Verify that the DOM container query is correct."
				);
				this._targetNode = document;
				if (this.observer) {
					this.observer.disconnect();
				}
			}
		}, DOM_NODE_WAIT_TIMEOUT);
	}

	_initTargetNode = () => {
		this._targetNode = document.querySelector(this._domContainerQuery);
		this._markInstance = new Mark(
			document.querySelector(this._domContainerQuery)
		);
	};

	_waitForTargetNode = () => {
		if (this.observer) return;
		const callback = (mutationsList, observer) => {
			mutationsList.forEach((mutation) => {
				mutation.addedNodes.forEach((addedNode) => {
					if (
						typeof addedNode.querySelector === "function" &&
						addedNode.querySelector(this._domContainerQuery)
					) {
						this.observer.disconnect();
						setTimeout(() => {
							logger.debug("Target DOM container found!");
							this._initTargetNode();
							this.MarkSentences();
						}, 200);
					}
				});
			});
		};
		this.observer = new MutationObserver(callback);
		this.observer.observe(document.querySelector("body"), {
			childList: true
		});
	};

	_createDomObserver = () => {
		logger.debug(
			"Trying to observe changes in node",
			this._targetNode,
			this._domContainerQuery
		);
		if (this._targetNode) {
			const config = { attributes: false, childList: true, subtree: true };

			// Callback function to execute when mutations are observed
			const callback = (mutationsList, observer) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const mutation of mutationsList) {
					if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
						let moveOn = false;
						if (this._lastMarkedAll === null) {
							this._lastMarkedAll = new Date();
							moveOn = true;
						}
						const diffInSeconds =
							Math.abs(new Date() - this._lastMarkedAll) / 1000;
						if (moveOn === true || diffInSeconds > 3) {
							this._lastMarkedAll = new Date();
							logger.warn(
								"DOM has been changed - re-marking sentences",
								mutation
							);
							let currentActiveSentences = this._getCurrentActiveSentence(
								this._targetNode
							);
							[].forEach.call(mutation.removedNodes, (removedNode) => {
								currentActiveSentences = currentActiveSentences.concat(
									this._getCurrentActiveSentence(removedNode)
								);
							});
							this.MarkSentences();
							[].forEach.call(currentActiveSentences, this._setSentenceActive);
							return;
						}
					}
				}
			};

			this.observer = new MutationObserver(callback);
			this.observer.observe(this._targetNode, config);
		} else {
			logger.warn("Target node is invalid, cannot create observer");
		}
	};

	_getCurrentActiveSentence = (root) => {
		root = root || document;
		const currentActiveSentences = [];
		const elems = root.querySelectorAll
			? root.querySelectorAll(MARKER_OPTIONS.ElementName + ".active")
			: [];
		[].forEach.call(elems, function (el) {
			currentActiveSentences.push(
				[...el.classList].filter(function (name) {
					return name.startsWith("sentence");
				})
			);
		});
		return currentActiveSentences;
	};

	_setSentenceActive = (sentenceClassName) => {
		logger.debug("Setting sentence " + sentenceClassName + " active");
		const results = this._targetNode.getElementsByClassName(sentenceClassName);
		if (results.length) {
			[].forEach.call(results, (el) => {
				el.classList.add("active");
				el.style.background = this._backgroundColor;
				el.style.color = this._color;
			});
			const element = results[0];
			element.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	Destroy = () => {
		if (this.observer) {
			this.observer.disconnect();
		}
	};

	Unmark = () => {
		if (this._markInstance) {
			this._markInstance.unmark();
		}
	}

	MarkSentences = () => {
		if (!this._targetNode) {
			logger.warn(
				"Target DOM container is not set, we might still be waiting for it to be created"
			);
			return;
		}

		let aggregatedNodeValues = "";
		let isRetry = false;
		let currentSentenceOrder = -1;
		this._missingSentences = [];

		logger.debug("Marking sentences in article");

		if (this.observer) {
			this.observer.disconnect();
		}

		const retry = (term) => {
			const test = term.replace(/ /g, "?");
			options.wildcards = "withSpaces";
			logger.warn("Retrying with term '" + test + "'", options);
			isRetry = true;
			this._markInstance.mark(test, options);
		};
		let options = {
			acrossElements: true,
			separateWordSearch: false,
			diacritics: false,
			exclude: [
				MARKER_OPTIONS.ApplicationContainer + " *",
				MARKER_OPTIONS.ElementName,
				".mjx-chtml *"
			],
			element: MARKER_OPTIONS.ElementName,
			noMatch: (term) => {
				logger.warn(
					"Found no matches for term '" +
						term +
						"' with PublisherDomContainer: " +
						this._domContainerQuery
				);
				if (!isRetry) {
					retry(term);
				} else {
					this._missingSentences.push(currentSentenceOrder);
				}
			},
			filter(node, term, totalMarkCounter, wordCounter) {
				const hasPreviousMark = totalMarkCounter >= 1;
				const isFullSentence =
					node.nodeValue.trim().includes(term.trim()) ||
					aggregatedNodeValues === term ||
					aggregatedNodeValues
						.replace(/\?/g, "")
						.replace(/\s/g, "")
						.includes(term.replace(/\?/g, "").replace(/\s/g, ""));
				const shouldContinue = !hasPreviousMark || !isFullSentence;
				aggregatedNodeValues += node.nodeValue;
				return shouldContinue;
			}
		};
		this._markInstance.unmark();

		for (let i = 0; i < this._sentences.length; i++) {
			const sentence = this._sentences[i];
			options.className = "sentence-" + sentence.Order;
			options.wildcards = "disabled";
			aggregatedNodeValues = "";
			isRetry = false;
			currentSentenceOrder = sentence.Order;
			this._markInstance.mark(sentence.Content, options);
		}

		this._lastMarkedAll = new Date();
		this._createDomObserver();
		this._removeEmptyMarkElements();
	};

	_removeEmptyMarkElements = () => {
		const elements = Array.from(
			this._targetNode.getElementsByTagName(MARKER_OPTIONS.ElementName)
		).filter((p) => p.textContent.trim() === "");
		elements.forEach((p) => {
			logger.debug("Removing empty element", p);
			p.parentNode.removeChild(p);
		});
	};

	SetAllSentencesInactive = () => {
		const elems = this._targetNode.querySelectorAll(MARKER_OPTIONS.ElementName);
		[].forEach.call(elems, function (el) {
			el.classList.remove("active");
			// eslint-disable-next-line no-param-reassign
			el.style = "";
		});
	};

	EnsureSentenceIsMarked = (sentence) => {
		if (!this._targetNode) {
			logger.warn(
				"Target DOM container is not set, we might still be waiting for it to be created"
			);
			return;
		}

		if (this._missingSentences.includes(sentence.Order)) {
			logger.warn(
				"Sentence '" + sentence.Content + "' is not marked, cannot show in DOM."
			);
			return;
		}

		const sentenceClass = "sentence-" + sentence.Order;

		const results = this._targetNode.getElementsByClassName(sentenceClass);

		if (!results.length) {
			logger.warn(
				`Found no matching marked sentence for ${sentenceClass} in DOM. Retrying markings.`
			);
			this.MarkSentences();
		}

		this.SetAllSentencesInactive();

		if (!sentence || !("Order" in sentence)) {
			logger.warn("EnsureSentenceIsMarked: Invalid sentence", sentence);
		} else {
			this._setSentenceActive("sentence-" + sentence.Order);
		}
	};
}

SentenceMarker.propTypes = {
	backgroundColor: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	domContainerQuery: PropTypes.string.isRequired,
	sentences: PropTypes.array.isRequired
};
