export default class RecordingQueueGuideItem {
    constructor(blob, articleId, guideId, sentenceOrder, recordedSeconds) {
        this.Blob = blob;
        this.GuideId = guideId;
        this.ArticleId = articleId;
        this.SentenceOrder = sentenceOrder;
        this.RecordedSeconds = recordedSeconds;
    }

    toString = () => {
        return (
            "GuideId: " +
            this.GuideId +
            " " +
            "SentenceOrder: " +
            this.SentenceOrder +
            " " +
            "RecordedSeconds: " +
            this.RecordedSeconds +
            " " +
            "Blob: " +
            this.Blob.size
        );
    };
}
