export const log = (...args) => {
	if (shouldLog()) {
		console.log(args.shift(), args);
	}
};
export const warn = (...args) => {
	if (window.console) {
		console.warn(args.shift(), args);
	}
};
export const error = (...args) => {
	if (window.console) {
		console.error(args.shift(), args);
	}
};
export const debug = (...args) => {
	if (shouldLog()) {
		console.debug(args.shift(), args);
	}
};
export const trace = (...args) => {
	if (shouldLog()) {
		console.trace(args.shift(), args);
	}
};
const shouldLog = () => {
	return (
		window.console &&
		process.env.REACT_APP_ENV &&
		!process.env.REACT_APP_ENV.startsWith("prod")
	);
};
