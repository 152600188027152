import * as cookies from "./cookies";
import * as constants from "../constants";
// import * as logger from "./logger";

// eslint-disable-next-line import/prefer-default-export
export const pad = val => {
	// eslint-disable-next-line prefer-template
	const valString = val + "";
	if (valString.length < 2) {
		return `0${valString}`;
	}
	return valString;
};

export const isEmptyOrSpaces = str => {
	return str == null || str === undefined || str.match(/^ *$/) !== null;
};

export const isArticleRecording = state => {
	return (
		state === constants.ARTICLE_STATE.Recording ||
		state === constants.ARTICLE_STATE.CorrectionsNeeded || 
		state === constants.ARTICLE_STATE.RecordingChanges
	);
};

export const isGuideRecording = state => {
	return (
		state === constants.GUIDE_STATE.Recording ||
		state === constants.GUIDE_STATE.CorrectionsNeeded
	);
};

export const debounce = (func, wait, immediate) => {
	let timeout;
	return (...args) => {
		const context = this;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const getAudioConstraints = () => {
	const deviceId = cookies.hasCookie("recorder.deviceId")
		? cookies.getCookie("recorder.deviceId")
		: null;
	const channelCount = cookies.hasCookie("recorder.channelCount")
		? parseInt(cookies.getCookie("recorder.channelCount"))
		: null;
	const sampleRate = cookies.hasCookie("recorder.sampleRate")
		? parseInt(cookies.getCookie("recorder.sampleRate"))
		: null;
	const echoCancellation = cookies.hasCookie("recorder.echoCancellation")
		? parseInt(cookies.getCookie("recorder.echoCancellation")) === 1
		: false;
	const noiseSuppression = cookies.hasCookie("recorder.noiseSuppression")
		? parseInt(cookies.getCookie("recorder.noiseSuppression")) === 1
		: false;

	const audioConstraints = {};
	if (deviceId) {
		audioConstraints.deviceId = { exact: deviceId };
	}
	if (channelCount) {
		audioConstraints.channelCount = channelCount;
	}
	if (sampleRate) {
		audioConstraints.sampleRate = sampleRate;
	}
	if (echoCancellation != null) {
		audioConstraints.echoCancellation = echoCancellation;
	}
	if (noiseSuppression != null) {
		audioConstraints.noiseSuppression = noiseSuppression;
	}

	// Turn auto-gain off to remove unexpected audio volume changes
	audioConstraints.autoGainControl = false;

	return audioConstraints;
};

export const batchPromises = (items, fn, options) => {
	const results = [];
	options = options || {
		batchSize: 3,
		retry: true
	};
	let index = options.batchSize - 1;

	function getNextItem() {
		index++;
		if (items.length > index) {
			const nextItem = items[index];
			return getCurrentItem(nextItem);
		}
	}

	function getCurrentItem(item) {
		return fn(item)
			.then(function(result) {
				results.push(result);
				return getNextItem();
			})
			.catch(function() {
				return options.retry ? getCurrentItem(item) : getNextItem();
			});
	}

	const promises = items.slice(0, options.batchSize).map(function(item) {
		return getCurrentItem(item);
	});
	return Promise.all(promises).then(function() {
		return results;
	});
};

export const getCleanedURL = () => {
	const regex = new RegExp('gleerupsportal\\.se');
	const url = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search;

	return regex.test(window.location.host) ? url.replace(/[?&]page=[^&]+/, '').replace(/^&/, '?') : url;
}
