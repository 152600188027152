import {toast} from "react-toastify";
import api from "../../api";
import errorHandler from "./errorHandler";
import * as logger from "../../helpers/logger";
import * as constants from "../../constants";
import * as util from "../../helpers/util";
import {getArticle} from "./article";

export const toggleEnabledGuides = () => {
    return (dispatch, getState) => {
        const {article} = getState();
        if (article) {
            if (article.GuidesEnabled) {
                dispatch({
                    type: "DISABLING_GUIDES"
                });
                return api.articles
                    .disableGuides(article.Id)
                    .then((data) => {
                        if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                        dispatch({
                            type: "GUIDES_DISABLED",
                            article: data
                        });
                        return data;
                    })
                    .then(() =>
                        dispatch(getArticle(article.Url))
                    )
                    .catch((error) => {
                        logger.error(error, "Failed to disable article's guides");
                        toast.error(
                            error.indexOf("400") > -1
                                ? "Failed to disable article's guides! See log for more details."
                                : "Failed to disable guides. It might be guides are disabled for this publisher."
                        );
                        return Promise.reject(error);
                    });
            }

            dispatch({
                type: "ENABLING_GUIDES"
            });
            return api.guides
                .enableGuides(article.Id)
                .then((data) => {
                    if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                    dispatch({
                        type: "GUIDES_ENABLED",
                        article: data
                    });
                    return data;
                })
                .then(() =>
                    dispatch(getArticle(article.Url))
                )
                .catch((error) => {
                    logger.error(error, "Failed to enable article's guides");
                    toast.error(
                        error.indexOf("400") > -1
                            ? "Failed to enable article's guides! See log for more details."
                            : "Failed to enable guides. It might be guides are disabled for this publisher."
                    );
                    return Promise.reject(error);
                });

        }
    }
}

export const saveManuscript = (guide, manuscript) => {
    return (dispatch) => {
        return api.guides
            .postManuscript(guide.ArticleId, guide.Id, manuscript)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "SAVE_MANUSCRIPT",
                    guideId: guide.Id,
                    manuscript
                });
                toast.success("Successfully updated manuscript content.");
                return data;
            })
            .then(() => dispatch(fetchGuide(guide.ArticleId, guide.Id)))
            .catch((error) => {
                logger.error(error, "Failed to store guides manuscript");
                toast.error("Failed to store guides manuscript! See log for more details.");
                return Promise.reject(error);
            });
    };
};

export const confirmGuideManuscriptContent = (guide) => {
    return (dispatch) => {
        return api.guides
            .confirmManuscript(guide.ArticleId, guide.Id)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "CONFIRM_GUIDE_MANUSCRIPT",
                    guideId: guide.Id
                });
                toast.success("Successfully confirmed manuscript content.");
                return data;
            })
            .catch((error) => {
                logger.error(error, "Failed to confirm guide manuscript.");
                toast.error("Failed to confirm guides manuscript! See log for more details.");
                return Promise.reject(error);
            });
    };
};

export const disablePlayer = () => {
    return (dispatch) => {
        dispatch({
            type: "DISABLE_RECORDER",
        });
    };
}
export const enablePlayer = () => {
    return (dispatch) => {
        dispatch({
            type: "ENABLE_RECORDER",
        });
    };
}

export const fetchGuide = (articleId, guideId) => {
    return (dispatch) => {
        dispatch({
            type: "FETCHING_GUIDE"
        });
        return api.guides
            .fetchGuide(articleId, guideId)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "SET_GUIDE",
                    guide: data
                });
                return data;
            }).catch((error) => {
                logger.error(error, "Failed to fetch Guide.");
                toast.error("Failed to fetch Guide. See log for more details.");
                return Promise.reject(error);
            });
    };
}

export const fetchGuides = (articleId) => {
    return (dispatch) => {
        dispatch({
            type: "FETCHING_GUIDES"
        });
        return api.guides
            .fetchGuides(articleId)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "SET_GUIDES",
                    guides: data
                });
                return data;
            }).catch((error) => {
                logger.error(error, "Failed to fetch Guides.");
                toast.error("Failed to fetch Guides. See log for more details.");
                return Promise.reject(error);
            });
    };
}

export const getGuideRecordings = (articleId, guideId, cb) => {
    return async (dispatch) => {
        dispatch({
            type: "FETCHING_GUIDE_RECORDINGS"
        });
        return api.guides
            .getAudio(articleId, guideId)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "SET_GUIDE_RECORDINGS",
                    recordings: data,
                    time: new Date()
                });
                if (typeof cb === "function") cb(data);
                return data;
            })
            .catch((error) => {
                logger.error(error, "Failed to fetch recordings!");
                toast.error(
                    "Failed to fetch recordings! See the console for more information."
                );
                return Promise.reject(error);
            });
    };
};

export const getGuidesWorkedTime = (articleId, guideId) => {
    return async (dispatch) => {
        return api.guides
            .getWorkedTime(articleId, guideId)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                return data;
            })
            .catch((error) => {
                logger.error(error, "Failed to get worked time");
                toast.error(
                    "Failed to get worked time! See the console for more information."
                );
                return Promise.reject(error);
            });
    };
};

export const setGuideDone = (guide, redirectToNextArticle, cb) => {
    return async (dispatch) => {
        const guideId = guide.Id;

        switch (guide.State) {
            case constants.GUIDE_STATE.CorrectionsNeeded:
            case constants.GUIDE_STATE.Recording:
                return api.guides
                    .recordingsCompleted(guide.ArticleId, guideId)
                    .then((data) => {
                        if (errorHandler(dispatch, data))
                            return Promise.reject(data.errors);
                        dispatch({
                            type: "GUIDE_DONE",
                            article: data
                        });
                        if (typeof cb === "function") cb(data);

                        // TODO Implement getting of next article for guides.
                        // return api.articles.getNextArticleUrl(articleId);

                        return data;
                    })
                    .then((data) => {
                        toast.success(
                            "Great! The guide will now be sent to the proof listener for review. ",
                            {
                                autoClose: false
                            }
                        );
                        // TODO Implement getting of next article for guides.
                        // if (redirectToNextArticle) {
                        //     redirectToNextArticleIfAny(data);
                        // }
                        return data;
                    })
                    .catch((error) => {
                        logger.error(error, "Failed to set guide done");
                        toast.error(
                            "Failed to update guide! See console for more information."
                        );
                        return Promise.reject(error);
                    });
            case constants.GUIDE_STATE.ProofListening:
                return api.guides
                    .proofListenerCompleted(guide.ArticleId, guideId)
                    .then((data) => {
                        if (errorHandler(dispatch, data))
                            return Promise.reject(data.errors);
                        dispatch({
                            type: "GUIDE_DONE",
                            article: data
                        });
                        if (typeof cb === "function") cb(data);

                        // TODO Implement getting of next article for guides.
                        // return api.articles.getNextArticleUrl(articleId);

                        return data;
                    })
                    .then((data) => {
                        if (
                                guide.GuideSentences.some(
                                (p) => !util.isEmptyOrSpaces(p.Remark)
                            )
                        ) {
                            toast.success(
                                "Great! The guide will now be sent back to narrator for corrections. ",
                                {
                                    autoClose: false
                                }
                            );
                        } else {
                            toast.success(
                                "Great! The guide will now be processed and is pending administrator approval before getting published. ",
                                {
                                    autoClose: false
                                }
                            );
                        }
                        // TODO Implement getting of next article for guides.
                        // if (redirectToNextArticle) {
                        //     redirectToNextArticleIfAny(data);
                        // }
                        return data;
                    })
                    .catch((error) => {
                        logger.error(error, "Failed to set guide done");
                        toast.error(
                            "Failed to update guide! See console for more information."
                        );
                        return Promise.reject(error);
                    });
            default:
                logger.warn(
                    "Guide is not in a valid state to proceed",
                    guide.State
                );
        }
    };
};