import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PlayerRecorderRnd from "../playerRecorderRnd";
import SentencesRnd from "../sentencesRnd";
import StudentPlayerRnd from "../studentPlayerRnd";
import RemarkRnd from "../remarkRnd";
import SettingsRnd from "../settingsRnd";
import { connector } from "../../store";
import { isStudent } from "../../helpers/authHelpers";
import * as util from "../../helpers/util";
import * as cookies from "../../helpers/cookies";
import ManuscriptsRnd from "../manuscriptRnd";

import "./index.css";

class AppContainer extends PureComponent {
	constructor(props) {
		super(props);
		window.addEventListener(
			"resize",
			util.debounce(this._handleResize, 300, false)
		);
		document.addEventListener("keydown", this._onKeyDown);
		window.resetToDefaultSettings = this._resetToDefaultSettings;
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._onKeyDown);
	}

	_onKeyDown = (e) => {
		if (e.code === "BracketLeft" && (e.ctrlKey || e.shiftKey)) {
			e.preventDefault();
			this._resetToDefaultSettings();
		}
	};

	_handleResize = () => {
		if (window.loadPropertiesFromCookie) {
			Object.keys(window.loadPropertiesFromCookie).forEach(function (key) {
				window.loadPropertiesFromCookie[key]();
			});
		}
	};

	_resetToDefaultSettings = () => {
		Object.keys(window.resetPositionCookies).forEach(function (key) {
			window.resetPositionCookies[key]();
		});

		cookies.deleteCookie("player.playback_rate");
		cookies.deleteCookie("player.auto_play");
	};

	render() {
		const { user, article } = this.props;

		if (isStudent(user)) {
			return (
				<div className="rnd-container">
					<StudentPlayerRnd />
				</div>
			);
		}
		return (
			<div className="rnd-container">
				<SentencesRnd />
				{article.Id && <PlayerRecorderRnd />}
				{article.Id && <RemarkRnd />}
				{article.Id && <SettingsRnd />}
				{article.Id && <ManuscriptsRnd />}
			</div>
		);
	}
}

AppContainer.propTypes = {
	article: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default connector(AppContainer, (props) => ({
	article: props.article,
	user: props.user
}));
