export default (request) => ({
	get(url) {
		if (!url) {
			throw new Error("Cannot fetch article, url is not set");
		}
		return request({
			path: `articles/url?url=${url}`,
			method: "get"
		});
	},
	getAudio(articleId) {
		if (!articleId) {
			throw new Error("Cannot fetch recordings, article ID not set");
		}
		return request({
			path: `articles/${articleId}/audio`,
			method: "get"
		});
	},
	getFullAudio(articleId) {
		if (!articleId) {
			throw new Error("Cannot fetch recordings, article ID not set");
		}
		return request({
			path: `articles/${articleId}/audio/full`,
			method: "get"
		});
	},
	getWorkedTime(articleId) {
		if (!articleId) {
			throw new Error("Cannot get worked time, article ID not set");
		}
		return request({
			path: `articles/${articleId}/worktime`,
			method: "get"
		});
	},
	recordingsCompleted(articleId) {
		if (!articleId) {
			throw new Error("Cannot set recordings completed, article ID not set");
		}
		return request({
			path: `articles/${articleId}/recordingsCompleted`,
			method: "patch"
		});
	},
	proofListenerCompleted(articleId) {
		if (!articleId) {
			throw new Error(
				"Cannot set proof listening completed, article ID not set"
			);
		}
		return request({
			path: `articles/${articleId}/proofListenerCompleted`,
			method: "patch"
		});
	},
	getNextArticleUrl(articleId) {
		return request({
			path: `articles/next?currentArticleId=${articleId}`,
			method: "get"
		});
	},
	disableGuides(articleId) {
		if (!articleId) {
			throw new Error("Cannot disable guides, article ID not set");
		}

		return request({
			path: `articles/disableGuides?articleId=${articleId}`,
			method: "patch"
		});
	}
});
