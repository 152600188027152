/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function PlayButton(props) {
	const { disabled } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-play button button--play", {
				"button--disabled": disabled
			})}
			aria-label="Play"
			onClick={() => props.onClick()}
		/>
	);
}

PlayButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool
};

PlayButton.defaultProps = {
	onClick: () => {},
	disabled: false
};
