export const setUrl = url => {
	return dispatch => {
		dispatch({
			type: "SET_URL",
			url
		});
	};
};

export default setUrl;
