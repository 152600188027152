import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

const middleware = [thunk];

export default (...args) => {
	const composeEnhancers =
		// eslint-disable-next-line no-underscore-dangle
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		reducers,
		...args,
		composeEnhancers(applyMiddleware(thunk, ...middleware))
	);

	return store;
};
