import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.min";
import { toast } from "react-toastify";
import * as logger from "../helpers/logger";
import * as util from "../helpers/util";
import * as cookies from "../helpers/cookies";

const height = 128 * window.devicePixelRatio;
const fadeIntervalMs = 15;
const fadeDurationSeconds = 0.3;

export default class WaveSurferAudio {
	constructor(container) {
		this._container = container;
		this._init(false);
	}

	_init = (isRecording) => {
		const ctx = document.createElement("canvas").getContext("2d");
		const linGrad = ctx.createLinearGradient(0, 0, 0, height);
		const high = "rgba(255, 96, 79, 1.000)";
		const medium = "rgb(255, 240, 79, 1.000)";
		const low = "rgb(102, 198, 0, 1.000)";

		linGrad.addColorStop(0, high);
		linGrad.addColorStop(0.4, medium);
		linGrad.addColorStop(0.48, low);
		linGrad.addColorStop(0.52, low);
		linGrad.addColorStop(0.6, medium);
		linGrad.addColorStop(1.0, high);

		WaveSurfer.microphone = MicrophonePlugin;
		if (this._container && document.querySelector(this._container)) {
			document.querySelector(this._container).innerHTML = "";
		}
		this._audio = WaveSurfer.create({
			container: this._container,
			// The color can be either a simple CSS color or a Canvas gradient
			waveColor: linGrad,
			progressColor: "hsla(232, 100%, 50%, 0.35)",
			cursorColor: "#ccc",
			// This parameter makes the waveform look like SoundCloud's player
			barWidth: 3,
			plugins: [WaveSurfer.microphone.create()],
			backend: isRecording ? "WebAudio" : "MediaElement"
		});

		this._src = "";
		this._isReadyToPlay = false;
		this._isPlayRequested = false;
		this._fadeTimer = null;

		this._audio.un("error", this._onAudioError);
		this._audio.un("ready", this._onReadyToPlay);
		this._audio.un("finish", this._onFinish);
		this._audio.un("waveform-ready", this._drawBaseLine);

		this._audio.on("waveform-ready", this._drawBaseLine);
		this._audio.on("error", this._onAudioError);
		if (!isRecording) {
			this._audio.on("ready", this._onReadyToPlay);
			this._audio.on("finish", this._onFinish);
		} else {
			this._audio.on("ready", this._drawBaseLine);
		}

		window.draw = this._drawBaseLine;
	};

	componentWillUnmount = () => {
		if (this._fadeTimer) {
			clearInterval(this._fadeTimer);
		}
	};

	_drawBaseLine = () => {
		setTimeout(() => {
			const canvases = document.querySelectorAll("wave canvas");
			canvases.forEach((canvas) => {
				const h = canvas.height;
				const w = canvas.width;
				const ctx = canvas.getContext("2d");
				ctx.globalCompositeOperation = "destination-over";
				// logger.debug(
				// 	"Drawing line from (0," + h / 2 + ") to (" + w + "," + h / 2 + ")",
				// 	canvas
				// );
				ctx.beginPath();
				ctx.lineWidth = 1;
				ctx.strokeStyle = "lightgrey";
				ctx.moveTo(0, h / 2);
				ctx.lineTo(w, h / 2);
				ctx.stroke();
			});
		}, 1000);
	};

	_onFinish = () => {
		this._isPlayRequested = false;
		if (this._fadeTimer) {
			clearInterval(this._fadeTimer);
		}
		if (typeof this.onAudioFinishCallback === "function") {
			this.onAudioFinishCallback();
		}
	};

	_onReadyToPlay = () => {
		this._isReadyToPlay = true;
		this._drawBaseLine();
		if (typeof this.onReadyCallback === "function") {
			this.onReadyCallback();
		}
		if (this._isPlayRequested) {
			this._audio.play();
			this._audio.setVolume(0);
			this._fadeTimer = setInterval(this._fade, fadeIntervalMs);
		}
	};

	_onAudioError = (error) => {
		logger.warn("Audio error", error, this._src);
		if (!error.message || !error.message.indexOf("XHR error")) {
			toast.error("Failed to fetch recording for sentence");
		}
	};

	_fade = () => {
		let volume = 0;
		const fadeDurationMs =
			(window.fadeDurationSeconds || fadeDurationSeconds) * 1000;
		const currentTimeMs = this._audio.getCurrentTime() * 1000;
		const durationMs = this._audio.getDuration() * 1000;
		// if (durationMs - currentTimeMs <= fadeDurationMs) {
		// 	volume = this._audio.getVolume() - 1 / (fadeDurationMs / fadeIntervalMs);
		// 	this._audio.setVolume(volume > 0 ? volume : 0);
		// }
		if (currentTimeMs <= fadeDurationMs && currentTimeMs > 0) {
			volume = this._audio.getVolume() + 1 / (fadeDurationMs / fadeIntervalMs);
			this._audio.setVolume(volume < 1 ? volume : 1);
		}
		if (
			currentTimeMs > fadeDurationMs &&
			durationMs - currentTimeMs > fadeDurationMs
		) {
			this._audio.setVolume(1);
		}
	};

	load = (src) => {
		this._src = src;
		this.reload();
	};

	reload = () => {
		if (this._src && !util.isEmptyOrSpaces(this._src)) {
			try {
				this._audio.load(this._src);
			} catch (e) {
				logger.error(
					"Failed to load audio from source ",
					this._src,
					e,
					this._audio
				);
				// setTimeout(this.reload, 1000);
			}
		}
		this._isReadyToPlay = false;
		if (this._fadeTimer) {
			clearInterval(this._fadeTimer);
		}
	};

	setPlaybackRate = (speed) => {
		this.playbackRate = parseFloat(speed.toPrecision(3));
		this._audio.setPlaybackRate(this.playbackRate);
		cookies.setCookie("player.playback_rate", speed.toPrecision(3));
	};

	play = () => {
		if (this._isReadyToPlay) {
			this._audio.play();
			this._audio.setVolume(0);
			this._fadeTimer = setInterval(this._fade, fadeIntervalMs);
		} else {
			this._isPlayRequested = true;
		}
	};

	pause = () => {
		this._audio.pause();
		if (this._fadeTimer) {
			clearInterval(this._fadeTimer);
		}
		this._isPlayRequested = false;
	};

	stop = () => {
		this.pause();
	};

	addEventListener = (event, cb) => {
		if (event === "ended") {
			this.onAudioFinishCallback = cb;
		}
		if (event === "ready") {
			this.onReadyCallback = cb;
		}
	};

	startRecording = () => {
		this._init(true);
		this._audio.microphone.on("deviceReady", function (stream) {});
		this._audio.microphone.on("deviceError", function (code) {
			logger.warn("(Wavesurfer mic) Recording error: " + code);
			toast.error("(Wavesurfer mic) Recording error: " + code);
		});
		this._audio.microphone.start();
	};

	stopRecording = () => {
		this._audio.microphone.stop();
		this._init(false);
	};
}
