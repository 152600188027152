/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import "./index.css";
import Tooltip from "react-tooltip-lite";

export default function ExclamationIcon(props) {
	const { message, className } = props;
	return (
		<Tooltip
			content={<div key={Math.random()}>{message}</div>}
			direction="right"
		>
			<div
				key={Math.random()}
				className={classNames(
					"exclamation-icon fa fa-exclamation-triangle",
					className
				)}
			/>
		</Tooltip>
	);
}
