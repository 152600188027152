import qs from "qs";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import * as logger from "../helpers/logger";
import HTTP_STATUS_CODES from "./httpStatusCodes";

const buildHeaders = (headers) => {
	return Auth.currentSession().then((data) => ({
		...headers,
		Authorization: `Bearer ${data.getIdToken().getJwtToken()}`
	}));
};

const Requestor = ({ apiUrl, heads = {} }) => (req) =>
	buildHeaders(heads).then((headers) => {
		// make fetchcall with apicall
		const { path, method, data = {}, body = undefined } = req;
		const queryString = data && qs.stringify(data, { encode: true });
		const requestUrl = getRequestUrl(apiUrl, path, queryString);
		const options = {
			headers,
			mode: "cors",
			credentials: "omit",
			method: method.toUpperCase()
		};
		if (method.toUpperCase() !== "GET" && typeof body !== "undefined") {
			options.body =
				headers["Content-Type"] === "application/vnd.api+json"
					? JSON.stringify(body)
					: body;
		}
		try {
			return makeRequest(requestUrl, options).catch((err) => {
				logger.error(err, "makeRequest failed");
			});
		} catch (err) {
			logger.error(err, "Caught exception during makeRequest");
			return null;
		}
	});

const getRequestUrl = (apiUrl, path, queryString) => {
	let url = String(apiUrl);
	if (url.endsWith("/")) {
		url = url.slice(0, -1);
	}

	return `${url}/${path}${queryString ? `?${queryString}` : ""}`;
};

const makeRequest = (url, options) => {
	logger.debug(`Requesting ${url}`, options);
	let responseCopy;
	return fetch(url, options)
		.then((response) => {
			logger.debug(
				`Got response (${response.status} ${
					HTTP_STATUS_CODES[response.status]
				}) from ${options.method} ${url}`,
				response
			);
			responseCopy = response.clone();
			if (response.status >= 200 && response.status <= 300) {
				if (response.status === 204) {
					return null;
				}
			} else {
				return handleUnexpectedError(
					url,
					`${response.status} ${HTTP_STATUS_CODES[response.status]}`
				);
			}

			if (
				response.headers.get("content-type").indexOf("application/json") !== -1
			) {
				return response
					.json()
					.then((responseBody) => {
						if (!response.ok) {
							logger.warn(responseBody);
							toast.error(responseBody.message);
							return {
								...responseBody,
								errors: [responseBody.message]
							};
						}
						return responseBody;
					})
					.catch((err) => {
						return responseCopy.text().then((responseBody) => {
							return handleUnexpectedError(
								url,
								err.message + ". Response message: " + responseBody
							);
						});
					});
			}
			return response.blob();
		})
		.catch((err) => {
			return handleUnexpectedError(url, err.message);
		});
};

const handleUnexpectedError = (url, err) => {
	if (err.indexOf("Internal Server Error") > -1) {
		toast.error(
			"A request to the server was unsuccessful. Please try again later.",
			{
				autoClose: 10000
			}
		);
	} else {
		toast.error("Cannot contact the server! Please try again later.", {
			autoClose: 10000
		});
	}

	logger.error("Unsuccessful API call to", url, "Reason: ", err);
	return {
		errors: [`Unexpected error: ${err}`]
	};
};

export default Requestor;
