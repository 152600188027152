import React, { Component } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip-lite";
import IltDtmnPlayer from "@inlasningstjanst/dtmn_player";
import StopRecordingButton from "../buttons/stopRecording";
import PlayButton from "../buttons/play";
import { connector } from "../../store";
import RndWrapper from "../rndWrapper";
import PauseButton from "../buttons/pause";
import LogoutButton from "../buttons/logout";
import PrevButton from "../buttons/prev";
import NextButton from "../buttons/next";

class StudentPlayerRnd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPlaying: false,
			playbackSpeed: 1.0
		};
	}

	componentDidMount() {
		if (this._player && !this._player.isAvailable()) {
			toast.error(
				"This article is not in published state, student player not available"
			);
		}
		this._setRndDefaults();
	}

	_onPlay = () => {
		this.setState({ isPlaying: true });
		this._player.play();
	};

	_onPause = () => {
		this.setState({ isPlaying: false });
		this._player.pause();
	};

	_onStop = () => {
		this.setState({ isPlaying: false });
		this._player.reset();
	};

	_onNext = () => {
		this.setState({ isPlaying: true });
		this._player.nextSentence();
	};

	_onPrevious = () => {
		this.setState({ isPlaying: true });
		this._player.prevSentence();
	};

	_onChangePlaybackSpeed = () => {
		const { playbackSpeed } = this.state;
		switch (playbackSpeed) {
			case 1.0:
				this.setState({ playbackSpeed: 1.5 });
				break;
			case 1.5:
				this.setState({ playbackSpeed: 0.5 });
				break;
			case 0.5:
				this.setState({ playbackSpeed: 1.0 });
				break;
			default:
				this.setState({ playbackSpeed: 1.0 });
		}
	};

	_onAudioEnded = () => {
		this.setState({
			isPlaying: false
		});
	};

	_setRndDefaults = () => {
		if (window.studentPlayerRnd) {
			window.studentPlayerRnd.loadPropertiesFromCookie();
		}
	};

	render() {
		const { action } = this.props;
		const { isPlaying, playbackSpeed } = this.state;
		return (
			<div className="height-50">
				<RndWrapper
					ref={(c) => {
						window.studentPlayerRnd = c;
					}}
					isVisible
					defaultWidth={300}
					defaultHeight={38}
					left={200}
					top={50}
					className="studentPlayer"
					title="Player"
					header={
						<div className="height-100">
							<div className="player-buttons">
								<div style={{ float: "left" }}>
									<Tooltip content="Log out" direction="up">
										<LogoutButton
											type="button"
											className="logout-button"
											onClick={action.logout}
										/>
									</Tooltip>
								</div>
								<div>
									<div style={{ float: "left" }}>
										<Tooltip content="Previous sentence" direction="up">
											<PrevButton
												onClick={this._onPrevious}
												autoDisable={false}
											/>
										</Tooltip>
									</div>

									<div style={{ float: "left" }}>
										<Tooltip content="Next sentence" direction="up">
											<NextButton onClick={this._onNext} autoDisable={false} />
										</Tooltip>
									</div>

									<div style={{ float: "left" }}>
										{!isPlaying && (
											<Tooltip content="Play" direction="up">
												<PlayButton onClick={this._onPlay} />
											</Tooltip>
										)}
										{isPlaying && (
											<Tooltip content="Pause" direction="up">
												<PauseButton onClick={this._onPause} />
											</Tooltip>
										)}
									</div>

									<div style={{ float: "left" }}>
										<Tooltip content="Stop" direction="up">
											<StopRecordingButton onClick={this._onStop} />
										</Tooltip>
									</div>

									<div style={{ float: "left" }}>
										<Tooltip content="Set playback speed" direction="up">
											<span onClick={this._onChangePlaybackSpeed}>
												x{playbackSpeed}
											</span>
										</Tooltip>
									</div>
								</div>
							</div>
						</div>
					}
				/>
				<IltDtmnPlayer
					ref={(player) => {
						this._player = player;
					}}
					backgroundColor="blue"
					color="white"
					onAudioEnded={this._onAudioEnded}
					playbackSpeed={playbackSpeed}
					apiUrl={process.env.REACT_APP_ILT_STUDENT_API_URL}
					token="my-token"
				/>
			</div>
		);
	}
}

StudentPlayerRnd.propTypes = {
	action: PropTypes.shape({
		logout: PropTypes.func.isRequired
	}).isRequired
};

export default connector(StudentPlayerRnd, (props) => ({
	article: props.article,
	action: {
		logout: props.action.logout
	}
}));
