import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import Store from "./store/index";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import * as constants from "./constants";

import "@jsweb/font-awesome-base64/dist/fa-all.css";

const WRAPPER_CLASS = `${constants.APP_CONTAINER_NAME}-wrap`;
const appElement = document.createElement("div");
appElement.setAttribute("id", constants.APP_CONTAINER_NAME);
appElement.classList.add(WRAPPER_CLASS);
document.querySelector("body").append(appElement);

window.dtmn_env = process.env.REACT_APP_ENV;

const Root = () => (
	// JSX is not valid javascript, it needs to be transpiled first
	<Provider store={Store}>
		<App />
	</Provider>
);

ReactDOM.render(
	<Root />,
	document.getElementById(constants.APP_CONTAINER_NAME)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
