const awsConfig = {
	// REQUIRED - Amazon Cognito Region
	region: process.env.REACT_APP_AWS_CONFIG_REGION || null,

	// OPTIONAL - Amazon Cognito User Pool ID
	userPoolId: process.env.REACT_APP_AWS_CONFIG_USER_POOL_ID || null,

	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	userPoolWebClientId:
		process.env.REACT_APP_AWS_CONFIG_USER_POOL_CLIENT_ID || null,

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	mandatorySignIn: true,

	authenticationFlowType: "USER_PASSWORD_AUTH"
};
export default awsConfig;
