import { toast } from "react-toastify";
import api from "../../api";
import errorHandler from "./errorHandler";
import * as constants from "../../constants";
import * as logger from "../../helpers/logger";
import * as util from "../../helpers/util";
import {fetchGuides} from "./guides";

export const setArticleDone = (article, redirectToNextArticle, cb) => {
	return async (dispatch) => {
		const articleId = article.Id;
		switch (article.State) {
			case constants.ARTICLE_STATE.CorrectionsNeeded:
			case constants.ARTICLE_STATE.RecordingChanges:
			case constants.ARTICLE_STATE.Recording:
				return api.articles
					.recordingsCompleted(articleId)
					.then((data) => {
						if (errorHandler(dispatch, data))
							return Promise.reject(data.errors);
						dispatch({
							type: "ARTICLE_DONE",
							article: data
						});
						if (typeof cb === "function") cb(data);
						return api.articles.getNextArticleUrl(articleId);
					})
					.then((data) => {
						toast.success(
							"Great! The article will now be sent to the proof listener for review. ",
							{
								autoClose: false
							}
						);
						if (redirectToNextArticle) {
							redirectToNextArticleIfAny(data);
						}
						return data;
					})
					.catch((error) => {
						logger.error(error, "Failed to set article done");
						toast.error(
							"Failed to update article! See console for more information."
						);
						return Promise.reject(error);
					});
			case constants.ARTICLE_STATE.ProofListening:
				return api.articles
					.proofListenerCompleted(articleId)
					.then((data) => {
						if (errorHandler(dispatch, data))
							return Promise.reject(data.errors);
						dispatch({
							type: "ARTICLE_DONE",
							article: data
						});
						if (typeof cb === "function") cb(data);
						return api.articles.getNextArticleUrl(articleId);
					})
					.then((data) => {
						if (
							article.SentencesCurrentVersion.some(
								(p) => !util.isEmptyOrSpaces(p.Remark)
							)
						) {
							toast.success(
								"Great! The article will now be sent back to narrator for corrections. ",
								{
									autoClose: false
								}
							);
						} else {
							toast.success(
								"Great! The article will now be processed and is pending administrator approval before getting published. ",
								{
									autoClose: false
								}
							);
						}
						if (redirectToNextArticle) {
							redirectToNextArticleIfAny(data);
						}
						return data;
					})
					.catch((error) => {
						logger.error(error, "Failed to set article done");
						toast.error(
							"Failed to update article! See console for more information."
						);
						return Promise.reject(error);
					});
			default:
				logger.warn(
					"Article is not in a valid state to proceed",
					article.State
				);
		}
	};
};

export const getArticle = (url, cb) => {
	return async (dispatch) => {
		// This means the application is not accessed via bookmark
		// No need to try to fetch article as it is not a valid article url
		if (
			url.includes("localhost") ||
			url.includes("recorder.") ||
			url.includes("/error")
		)
			return;

		dispatch({
			type: "FETCHING_ARTICLE"
		});
		return api.articles
			.get(url)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_ARTICLE",
					article: data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then((data) => {
				if (data.GuidesEnabled) {
					dispatch(fetchGuides(data.Id));
				}

				return data;
			})
			.catch((error) => {
				logger.error(error, "Failed to get article");
				toast.error(
					error.indexOf("404") > -1
						? "The article does not exist or is not assigned to you."
						: "Failed to get article! See log for more details."
				);
				return Promise.reject(error);
			});
	};
};

export const getRecordings = (articleId, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "FETCHING_RECORDINGS"
		});
		return api.articles
			.getAudio(articleId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_RECORDINGS",
					recordings: data,
					time: new Date()
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch((error) => {
				logger.error(error, "Failed to fetch recordings!");
				toast.error(
					"Failed to fetch recordings! See the console for more information."
				);
				return Promise.reject(error);
			});
	};
};

export const downloadAudio = (articleId) => {
	return async (dispatch) => {
		toast.info("Please wait while download is being prepared...", {
			autoClose: 3000
		});
		return api.articles
			.getFullAudio(articleId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				return data;
			})
			.then((url) => fetch(url))
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = `${articleId}.webm`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.then(() => {
				return Promise.resolve();
			})
			.catch((error) => {
				logger.error(error, "Failed to download audio!");
				toast.error(
					"Failed to download audio! See the console for more information."
				);
				return Promise.reject(error);
			});
	};
};

export const getWorkedTime = (articleId) => {
	return async (dispatch) => {
		return api.articles
			.getWorkedTime(articleId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				return data;
			})
			.catch((error) => {
				logger.error(error, "Failed to get worked time");
				toast.error(
					"Failed to get worked time! See the console for more information."
				);
				return Promise.reject(error);
			});
	};
};

const redirectToNextArticleIfAny = (url) => {
	let message;
	if (util.isEmptyOrSpaces(url)) {
		message =
			"There is currently no more work. You can go ahead and close this window.";
	} else {
		message = "You will be redirected to the next article shortly...";
		setTimeout(() => {
			window.location.replace(url);
		}, 2000);
	}
	toast.info(message, {
		autoClose: false
	});
	logger.debug(url);
};