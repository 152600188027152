import React, { Component } from "react";
import { Rnd } from "react-rnd";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.css";
import * as cookies from "../../helpers/cookies";
import * as logger from "../../helpers/logger";

export default class RndWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isActive: false
		};
	}

	_onResizeStop = (e, dir, ref, delta, position) => {
		const { className } = this.props;
		// logger.debug("new size", ref.style.width, ref.style.height);
		cookies.setCookie(className + "-rnd-w", ref.style.width);
		cookies.setCookie(className + "-rnd-h", ref.style.height);
	};

	_onDragStop = (e, d) => {
		const { className } = this.props;
		const left = d.x || 0;
		const right = window.innerWidth - d.x;
		const top = d.y || 0;
		const bottom = window.innerHeight - d.y;
		logger.debug("rnd new pos", { left, right, top, bottom }, d);

		cookies.setCookie(className + "-rnd-left", left);
		cookies.setCookie(className + "-rnd-right", right);
		cookies.setCookie(className + "-rnd-top", top);
		cookies.setCookie(className + "-rnd-bottom", bottom);
	};

	_resetPositionCookies = () => {
		const { className } = this.props;
		const id = className;
		cookies.deleteCookie(id + "-rnd-left");
		cookies.deleteCookie(id + "-rnd-right");
		cookies.deleteCookie(id + "-rnd-top");
		cookies.deleteCookie(id + "-rnd-bottom");
		cookies.deleteCookie(id + "-rnd-w");
		cookies.deleteCookie(id + "-rnd-h");
		this.loadPropertiesFromCookie();
	};

	getCookieIntValue = (name) => {
		let value = cookies.getCookie(name);
		if (
			!value ||
			isNaN(parseInt(value)) ||
			parseInt(value) < 0 ||
			parseInt(value) > window.innerWidth
		)
			return null;
		return parseInt(value);
	};

	loadPropertiesFromCookie = () => {
		const {
			className,
			left,
			right,
			top,
			bottom,
			defaultHeight,
			defaultWidth
		} = this.props;
		const id = className;
		const posLeft = this.getCookieIntValue(id + "-rnd-left") || left;
		const posRight = this.getCookieIntValue(id + "-rnd-right") || right;
		const posTop = this.getCookieIntValue(id + "-rnd-top") || top;
		const posBottom = this.getCookieIntValue(id + "-rnd-bottom") || bottom;
		const width = this.getCookieIntValue(id + "-rnd-w") || defaultWidth;
		const height = this.getCookieIntValue(id + "-rnd-h") || defaultHeight;
		logger.debug(
			"loadPropertiesFromCookie",
			className,
			"posLeft: " + posLeft,
			"posRight: " + posRight,
			"posTop: " + posTop,
			"posBottom: " + posBottom,
			"x: " + this._selectBoundX(posLeft, posRight),
			"y: " + this._selectBoundY(posTop, posBottom),
			width,
			height
		);
		this.rnd.updatePosition({
			x: this._selectBoundX(posLeft, posRight),
			y: this._selectBoundY(posTop, posBottom)
		});
		this.rnd.updateSize({
			width: parseInt(width),
			height: parseInt(height)
		});

		if (!window.resetPositionCookies) {
			window.resetPositionCookies = {};
		}
		window.resetPositionCookies[className] = this._resetPositionCookies;
		if (!window.loadPropertiesFromCookie) {
			window.loadPropertiesFromCookie = {};
		}
		window.loadPropertiesFromCookie[className] = this.loadPropertiesFromCookie;
	};

	_selectBoundX = (left, right) => {
		if (left != null) left = parseInt(left);
		if (right != null) right = parseInt(right);

		if (left != null && right != null) {
			return left < right ? left : parseInt(window.innerWidth) - right;
		}
		if (left == null) return parseInt(window.innerWidth) - right;
		if (right == null) return left;
		logger.error("_selectBoundX: left and right bounds are both invalid!");
	};

	_selectBoundY = (top, bottom) => {
		if (top != null) top = parseInt(top);
		if (bottom != null) bottom = parseInt(bottom);

		if (top != null && bottom != null) {
			return top < bottom ? top : parseInt(window.innerHeight) - bottom;
		}
		if (top == null) return parseInt(window.innerHeight) - bottom;
		if (bottom == null) return top;
		logger.error("_selectBoundY: top and bottom bounds are both invalid!");
	};

	setActive = () => {
		this.setState({
			isActive: true
		});
	};

	setInactive = () => {
		this.setState({
			isActive: false
		});
	};

	render() {
		const {
			isVisible,
			className,
			// left,
			// right,
			// top,
			// bottom,
			defaultHeight,
			defaultWidth,
			minHeight,
			minWidth,
			enableResizing,
			maxHeight,
			maxWidth,
			title,
			header,
			tabs,
			content,
			footer
		} = this.props;
		const { isActive } = this.state;
		// const x = this._selectBoundX(left, right);
		// const y = this._selectBoundY(top, bottom);
		return (
			<Rnd
				style={{ display: isVisible ? "block" : "none" }}
				className={classNames(className, isActive ? "active" : "")}
				default={{
					x: 0,
					y: 0,
					height: defaultHeight,
					width: defaultWidth
				}}
				ref={(c) => {
					this.rnd = c;
				}}
				minHeight={minHeight > 0 ? minHeight : defaultHeight}
				minWidth={minWidth > 0 ? minWidth : defaultWidth}
				maxHeight={maxHeight > 0 ? maxHeight : defaultHeight}
				maxWidth={maxWidth > 0 ? maxWidth : defaultWidth}
				enableResizing={enableResizing ? { bottomRight: true } : false}
				dragHandleClassName={className + "-header"}
				onResizeStop={this._onResizeStop}
				onDragStop={this._onDragStop}
			>
				<div className={classNames(className + "-window", "rnd-window")}>
					<div className={classNames(className + "-header", "rnd-header")}>
						<div className="floated-containers" style={{display:"flex"}}>
							<img
								className="ilt-icon"
								alt=""
								title="Inläsningtjänst AB"
								src={process.env.REACT_APP_ILT_FRONTEND_URL + "/favicon.ico"}
							/>
							<p className={classNames(className + "-title", "rnd-title")}>
								{title}
							</p>
							<div
								className={classNames(
									className + "-header-content",
									"rnd-header-content"
								)}
							>
								{header}
							</div>
						</div>
						<div className="floated-containers">{tabs}</div>
					</div>
					<div className={classNames(className + "-content", "rnd-content")}>
						{content}
					</div>
					{footer && (
						<div className={classNames(className + "-footer", "rnd-footer")}>
							{footer}
						</div>
					)}
				</div>
			</Rnd>
		);
	}
}

RndWrapper.propTypes = {
	isVisible: PropTypes.bool,
	className: PropTypes.string.isRequired,
	defaultWidth: PropTypes.number.isRequired,
	defaultHeight: PropTypes.number.isRequired,
	minWidth: PropTypes.number,
	minHeight: PropTypes.number,
	maxWidth: PropTypes.number,
	maxHeight: PropTypes.number,
	left: PropTypes.number,
	right: PropTypes.number,
	top: PropTypes.number,
	bottom: PropTypes.number,
	title: PropTypes.string,
	tabs: PropTypes.object,
	enableResizing: PropTypes.bool,
	content: PropTypes.object,
	footer: PropTypes.object,
	header: PropTypes.object
};

RndWrapper.defaultProps = {
	isVisible: true,
	minWidth: 0,
	maxWidth: 0,
	minHeight: 0,
	maxHeight: 0,
	left: null,
	right: null,
	top: null,
	bottom: null,
	title: "",
	content: null,
	footer: null,
	header: null,
	enableResizing: false,
	tabs:null
};
