import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import {Popconfirm} from "antd";
import { connector } from "../../store";

class ManuscriptsFooter extends Component {

	_showSaveChangesButton = () => {
		const {manuscriptEditing} = this.props;
		if (manuscriptEditing){
			return true;
		}
	}

	_showEditManuscriptButton = () => {
		const {manuscriptEditing} = this.props;
		if (!manuscriptEditing){
			return true;
		}
	}

	render() {
		const {
			saveChangesCallback,
			editManuscriptCallback,
			cancelEditCallback,
			articleChangedSinceGuideManuscripting,
			confirmGuideManuscriptContent
		} = this.props;

		return (
			<div className="footer-container">
				{this._showSaveChangesButton() && (
					<div>
						<Popconfirm
							title="Confirm manuscript change"
							onConfirm={() => saveChangesCallback()}
							overlayStyle={{zIndex: "19000"}}
						>
							<button
								className="btn btn-block btn-primary button-done"
								type="button"
							>
								Save changes
							</button>
						</Popconfirm>
						<button
							className="btn btn-block btn-primary btn-danger"
							onClick={cancelEditCallback}
							type="button"
						>
							Cancel
						</button>
					</div>
				)}
				{this._showEditManuscriptButton() && articleChangedSinceGuideManuscripting && (
					<Popconfirm
						title="Confirm manuscript content?"
						onConfirm={() => confirmGuideManuscriptContent()}
						overlayStyle={{zIndex: "19000"}}
					>
						<button
							className="btn btn-block btn-primary button-done"
							type="button"
						>
							Confirm content
						</button>
					</Popconfirm>
				)}
				{this._showEditManuscriptButton() && (
					<button
						className="btn btn-block btn-primary button-edit"
						onClick={editManuscriptCallback}
						type="button"
					>
						Edit manuscript
					</button>
				)}
			</div>
		);
	}
}
ManuscriptsFooter.defaultProps = {
	articleChangedSinceGuideManuscripting:false
}

ManuscriptsFooter.propTypes = {
	manuscriptEditing: PropTypes.bool.isRequired,
	articleChangedSinceGuideManuscripting: PropTypes.bool,
	saveChangesCallback: PropTypes.func.isRequired,
	editManuscriptCallback: PropTypes.func.isRequired,
	cancelEditCallback: PropTypes.func.isRequired,
	confirmGuideManuscriptContent: PropTypes.func.isRequired,
};

export default connector(ManuscriptsFooter, (props) => ({
	article: props.article,
	user: props.user,
}));
