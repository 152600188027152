import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function PauseButton(props) {
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-pause button")}
			aria-label="Pause"
			onClick={() => props.onClick()}
		/>
	);
}

PauseButton.propTypes = {
	onClick: PropTypes.func
};

PauseButton.defaultProps = {
	onClick: () => {}
};
