export const setCookie = (key, val, exdays = 365 * 10) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = key + "=" + val + ";" + expires + ";path=/";
};
export const getCookie = key => {
	const name = key + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};
export const hasCookie = key => {
	const val = getCookie(key);
	return val !== undefined && val !== null && val !== "";
};
export const deleteCookie = key => {
	setCookie(key, "", -1);
};
