import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function NextButton(props) {
	const {
		selectedSentence,
		foundSentencesLength,
		autoDisable,
		onClick
	} = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-forward button", {
				"button--disabled":
					selectedSentence.foundIndex === foundSentencesLength - 1 &&
					autoDisable
			})}
			aria-label="Next"
			onClick={onClick}
		/>
	);
}

NextButton.propTypes = {
	selectedSentence: PropTypes.shape({
		id: PropTypes.number,
		index: PropTypes.number,
		foundIndex: PropTypes.number
	}),
	onClick: PropTypes.func,
	foundSentencesLength: PropTypes.number,
	autoDisable: PropTypes.bool
};

NextButton.defaultProps = {
	selectedSentence: { id: 0, index: 0, foundIndex: 0 },
	onClick: () => {},
	foundSentencesLength: 0,
	autoDisable: true
};
