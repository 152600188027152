import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function StartRecordingButton(props) {
	const { isPlaying, disabled, onClick } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-circle button button--start", {
				"button--disabled": isPlaying || disabled
			})}
			onClick={disabled ? () => {} : onClick}
			aria-label="Record"
			data-tooltip="(R)"
		/>
	);
}

StartRecordingButton.propTypes = {
	onClick: PropTypes.func,
	isPlaying: PropTypes.bool,
	disabled: PropTypes.bool
};

StartRecordingButton.defaultProps = {
	onClick: () => {},
	isPlaying: false,
	disabled: false
};
