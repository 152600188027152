import * as constants from "../constants";

export const getUserGroups = user => {
	return user.signInUserSession.idToken.payload["cognito:groups"];
};

export const groupContainsUser = (user, group) => {
	return (
		user.signInUserSession.idToken.payload["cognito:groups"].indexOf(group) > -1
	);
};

export const isAdmin = user => {
	return user && user.groups && user.groups.indexOf("admin") > -1;
};

export const isStudent = user => {
	return user && user.groups && user.groups.indexOf("student") > -1;
};

export const isNarrator = user => {
	return user && user.groups && user.groups.indexOf("narrator") > -1;
};
export const isGuideNarrator = user => {
	return user.groups && user.groups.findIndex(p => {
		return p.indexOf('narrator-') > -1
	}) > -1;
};
export const isGuideProofListener = user => {
	return user.groups && user.groups.findIndex(p => {
		return p.indexOf('prooflistener-') > -1
	}) > -1;
};
export const isProofListener = user => {
	return user && user.groups && user.groups.indexOf("prooflistener") > -1;
};
export const splitUsername = user => {
	return user.replace(/([A-Z]|Å|Ä|Ö)/g, " $1").trim();
};
export const getUserName = (userId, groups) => {
	const allusers = groups
		.map(function(p) {
			return p.Users;
		})
		.reduce(function(a, b) {
			return a.concat(b);
		});
	const user = allusers.find(u => u.Id === userId);
	if (user) {
		return splitUsername(user.Name);
	}
	return null;
};
export const getUsersInRole = (groups, role) => {
	const group = groups.filter(p => p.Role === role);
	return group && group.length > 0 ? group[0].Users : [];
};
export const getProofListeners = groups => {
	return getUsersInRole(groups, constants.USER_ROLE.ProofListener);
};
export const getNarrators = groups => {
	return getUsersInRole(groups, constants.USER_ROLE.Narrator);
};
export const getAdmins = groups => {
	return getUsersInRole(groups, constants.USER_ROLE.Admin);
};
export const getStudents = groups => {
	return getUsersInRole(groups, constants.USER_ROLE.Student);
};

export function isAuthenticated(user) {
	return (
		user &&
		user.groups &&
		(isAdmin(user) || isNarrator(user) || isProofListener(user) || isGuideNarrator(user) || isGuideProofListener(user))
	);
}
