import { combineReducers } from "redux";
import articleReducer from "./articleReducer";
import userReducer from "./userReducer";
import sentenceReducer from "./sentenceReducer";
import playerReducer from "./playerReducer";
import loadingReducer from "./loadingReducer";
import locationReducer from "./locationReducer";

export default combineReducers({
	article: articleReducer,
	user: userReducer,
	sentence: sentenceReducer,
	player: playerReducer,
	loading: loadingReducer,
	location: locationReducer
});
