export default class RecordingQueueItem {
	constructor(blob, articleId, sentenceOrder, recordedSeconds) {
		this.Blob = blob;
		this.ArticleId = articleId;
		this.SentenceOrder = sentenceOrder;
		this.RecordedSeconds = recordedSeconds;
	}

	toString = () => {
		return (
			"ArticleId: " +
			this.ArticleId +
			" " +
			"SentenceOrder: " +
			this.SentenceOrder +
			" " +
			"RecordedSeconds: " +
			this.RecordedSeconds +
			" " +
			"Blob: " +
			this.Blob.size
		);
	};
}
