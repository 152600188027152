export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
export const ARTICLE_STATE = Object.freeze({
	Undefined: 0,
	New: 1,
	Recording: 2,
	ProofListening: 3,
	CorrectionsNeeded: 4,
	ReadyForAudioProcessing: 5,
	ProcessingAudio: 9,
	ReadyForPublishing: 6,
	Published: 7,
	RecordingChanges: 8,
	Disabled: 10
});
export const GUIDE_STATE = Object.freeze({
	Undefined: 0,
	New: 1,
	Manuscripting: 2,
	Recording: 3,
	ProofListening: 4,
	CorrectionsNeeded: 5,
	ReadyForAudioProcessing: 6,
	ProcessingAudio: 9,
	ReadyForPublishing: 7,
	Published: 8,
	Disabled: 10
});
export const SENTENCE_STATE = Object.freeze({
	Undefined: 0,
	NotRecorded: 1,
	Recorded: 2,
	Changed: 3,
	Disabled: 4
});
export const USER_ROLE = Object.freeze({
	Undefined: 0,
	Admin: 100,
	Narrator: 200,
	ProofListener: 300,
	Student: 400
});
export const LOG_ENTRY_TYPE = Object.freeze({
	Unknown: 0,
	SentenceStateChanged: 1,
	ArticleStateChanged: 2,
	SentenceRecorded: 3,
	RemarkUpdated: 4,
	ArticleUpdated: 5,
	ArticleNarratorChanged: 6,
	ArticleProofListenerChanged: 7,
	ArticleCreated: 8,
	AudioProcessingFailed: 9
});
export const COLORS = Object.freeze({
	orange: "#ffd480",
	red: "#ff8a90",
	gray: "#b7b7b7",
	green: "#b4ef98",
	yellow: "#fdfd5d",
	blue: "#00a1c9",
	black: "#000",
	white: "#fff"
});

export const REMARKS_PRESETS = Object.freeze({
	default: { key: "Select pre-defined remark" },
	Digit0: { key: "Pauseringsfel (Ctrl + 0)", value: "Pauseringsfel" },
	Digit1: { key: "Saknad text (Ctrl + 1)", value: "Saknad text" },
	Digit2: { key: "Felaktigt uttal (Ctrl + 2)", value: "Felaktigt uttal" },
	Digit3: { key: "Överflödig text (Ctrl + 3)", value: "Överflödig text" },
	Digit4: { key: "Missljud (Ctrl + 4)", value: "Missljud" },
	Digit5: { key: "Felinläsning (Ctrl + 5)", value: "Felinläsning" },
	Digit6: { key: "Kapat ord (Ctrl + 6)", value: "Kapat ord" },
	Digit7: { key: "Omtagsfel (Ctrl + 7)", value: "Omtagsfel" },
	Digit8: { key: "Stakning (Ctrl + 8)", value: "Stakning" },
	Digit9: { key: "Ojämn ljudkvalitet (Ctrl + 9)", value: "Ojämn ljudkvalitet" }
});

export const PLAYER_KEYS = Object.freeze({
	TogglePlayback: {
		keyCode: "Space",
		secondary: false,
		text: "(Ctrl-Space)",
		preventDefault: false
	},
	PrevSentence: {
		keyCode: "ArrowLeft",
		secondary: true,
		text: "(Ctrl-Left)",
		preventDefault: true
	},
	NextSentence: {
		keyCode: "ArrowRight",
		secondary: true,
		text: "(Ctrl-Right)",
		preventDefault: true
	},
	ChangePlaybackRateUp: {
		keyCode: "ArrowUp",
		secondary: true,
		text: "(Ctrl-Up/Down)",
		preventDefault: true
	},
	ChangePlaybackRateDown: {
		keyCode: "ArrowDown",
		secondary: true,
		text: "(Ctrl-Up/Down)",
		preventDefault: true
	},
	RecordNextSentence: {
		keyCode: "KeyA",
		secondary: false,
		text: "(A)"
	},
	ToggleRecord: {
		keyCode: "KeyF",
		secondary: false,
		text: "(F)"
	},
	PauseResumeRecord: {
		keyCode: "KeyG",
		secondary: false,
		text: "(G)"
	}
});

export const SENTENCE_KEYS = Object.freeze({
	EnableDisableSentence: {
		keyCode: "KeyT",
		secondary: false,
		text: "(T)"
	}
});

export const ARTICLE_KEYS = Object.freeze({
	SubmitArticle: {
		keyCode: "KeyS",
		secondary: false,
		text: "(S)"
	},
	SubmitArticleYes: {
		keyCode: "KeyY",
		secondary: false,
		text: "(Y)"
	},
	SubmitArticleYesAndContinue: {
		keyCode: "KeyC",
		secondary: false,
		text: "(C)"
	}
});

export const PLAYER_STATUS = Object.freeze({
	Stopped: "stopped",
	Playing: "playing",
	Paused: "pause",
	Recording: "recording",
	AutoPlaying: "auto",
	Loading: "loading"
});

export const PLAYBACK_RATE = Object.freeze({
	min: 0.8,
	max: 3.0,
	default: 1.0,
	step: 0.2
});

export const APP_CONTAINER_NAME = "dtmn-application";
