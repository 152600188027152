/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import Tooltip from "react-tooltip-lite";
import { toast } from "react-toastify";
import RndWrapper from "../rndWrapper";
import { connector } from "../../store";
import { isAuthenticated } from "../../helpers/authHelpers";
import * as logger from "../../helpers/logger";
import * as cookies from "../../helpers/cookies";
import CloseButton from "../buttons/close";
import * as util from "../../helpers/util";

class SettingsRnd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			settingsVisible: false
		};
	}

	componentDidMount() {
		this._setRndDefaults();
		this._audioSelect = document.querySelector("select#audioDevice");
		navigator.mediaDevices
			.enumerateDevices()
			.then(this._gotDevices)
			.catch((error) => {
				logger.error(error, "Failed to get input devices");
			});

		const supported = navigator.mediaDevices.getSupportedConstraints();
		logger.debug("Supported constraints", supported);
	}

	componentDidUpdate = (prevProps, prevState) => {};

	componentWillUnmount() {}

	_shouldShowSettings = () => {
		const { sentence, user, article } = this.props;
		const { settingsVisible } = this.state;
		if (!isAuthenticated(user)) return false;
		if (!article?.Id || !(sentence.ArticleId || sentence.GuideId)) return false;
		if (!settingsVisible) return false;
		return true;
	};

	_setRndDefaults = () => {
		if (window.settingsRnd) {
			window.settingsRnd.loadPropertiesFromCookie();
			window.settingsRnd.Show = this._show;
		}
	};

	_gotDevices = (deviceInfos) => {
		const currentDevice = cookies.getCookie("player.deviceId");

		for (let i = 0; i !== deviceInfos.length; ++i) {
			const deviceInfo = deviceInfos[i];
			const option = document.createElement("option");
			option.value = deviceInfo.deviceId;
			if (deviceInfo.kind === "audioinput") {
				option.text =
					deviceInfo.label || "microphone " + (this._audioSelect.length + 1);
				if (option.value === currentDevice) {
					option.defaultSelected = true;
				}
				this._audioSelect.appendChild(option);
			}
		}
	};

	_setAudioDevice = (event) => {
		this._updateSetting(event.target.value, "recorder.deviceId", false);
	};

	_setChannelCount = (event) => {
		this._updateSetting(event.target.value, "recorder.channelCount", true);
	};

	_setSampleRate = (event) => {
		this._updateSetting(event.target.value, "recorder.sampleRate", true);
	};

	_setEchoCancellation = (event) => {
		this._updateSetting(event.target.value, "recorder.echoCancellation", true);
	};

	_setNoiseSuppression = (event) => {
		this._updateSetting(event.target.value, "recorder.noiseSuppression", true);
	};

	_updateSetting = (value, setting, isInt) => {
		let valueTyped = value;
		if (isInt) {
			valueTyped = parseInt(value);
			if (Number.isNaN(valueTyped)) valueTyped = null;
		}
		logger.debug("Setting " + setting + " to ", valueTyped);
		if (valueTyped === null) {
			cookies.deleteCookie(setting);
		} else {
			cookies.setCookie(setting, valueTyped);
		}
	};

	_showSupportedSettings = () => {
		const supported = navigator.mediaDevices.getSupportedConstraints();
		const supportedString = JSON.stringify(supported, null, 2);
		toast.info(supportedString, {
			autoClose: false
		});
	};

	_hide = () => {
		this.setState({
			settingsVisible: false
		});
	};

	_show = () => {
		this.setState({
			settingsVisible: true
		});
	};

	render() {
		const audioConstraints = util.getAudioConstraints();

		return (
			<div className="height-100">
				<RndWrapper
					ref={(c) => {
						window.settingsRnd = c;
					}}
					defaultWidth={400}
					defaultHeight={280}
					isVisible={this._shouldShowSettings()}
					right={500}
					top={300}
					className="settings"
					title="Settings"
					header={
						<div className="height-100">
							<div style={{ float: "right" }}>
								<Tooltip content="Close" direction="up">
									<CloseButton
										type="button"
										className="logout-button"
										onClick={this._hide}
									/>
								</Tooltip>
							</div>
						</div>
					}
					content={
						<div className="height-100" id="settings-container">
							<div className="setting">
								<label htmlFor="audioDevice">
									<b>Input device</b>
								</label>
								<select
									id="audioDevice"
									name="audioDevice"
									onChange={this._setAudioDevice}
								>
									<option value="">Browser default</option>
								</select>
							</div>
							<div className="setting">
								<label htmlFor="channelCount">
									<b>Channel count</b>
								</label>
								<select
									id="channelCount"
									name="channelCount"
									onChange={this._setChannelCount}
									defaultValue={audioConstraints.channelCount}
								>
									<option value="">Browser default</option>
									<option value="1">1 (Mono)</option>
									<option value="2">2 (Stereo)</option>
								</select>
							</div>
							<div className="setting">
								<label htmlFor="sampleRate">
									<b>Sample rate</b>
								</label>
								<select
									id="sampleRate"
									name="sampleRate"
									defaultValue={audioConstraints.sampleRate}
									onChange={this._setSampleRate}
								>
									<option value="">Browser default</option>
									<option value="44000">44000 kHz</option>
									<option value="48000">48000 kHz</option>
								</select>
							</div>
							<div className="setting">
								<label htmlFor="echoCancellation">
									<b>Echo cancellation</b>
								</label>
								<select
									id="echoCancellation"
									name="echoCancellation"
									defaultValue={
										audioConstraints.echoCancellation === true
											? "1"
											: audioConstraints.echoCancellation === false
											? "0"
											: ""
									}
									onChange={this._setEchoCancellation}
								>
									{/* <option value="">Browser default</option> */}
									<option value="1">On</option>
									<option value="0">Off</option>
								</select>
							</div>
							<div className="setting">
								<label htmlFor="noiseSuppression">
									<b>Noise suppression</b>
								</label>
								<select
									id="noiseSuppression"
									name="noiseSuppression"
									defaultValue={
										audioConstraints.noiseSuppression === true
											? "1"
											: audioConstraints.noiseSuppression === false
											? "0"
											: ""
									}
									onChange={this._setNoiseSuppression}
								>
									{/* <option value="">Browser default</option> */}
									<option value="1">On</option>
									<option value="0">Off</option>
								</select>
							</div>

							<div className="setting">
								<button
									type="button"
									className="button"
									onClick={this._showSupportedSettings}
								>
									Display supported values
								</button>
							</div>
						</div>
					}
				/>
			</div>
		);
	}
}

SettingsRnd.propTypes = {
	sentence: PropTypes.object.isRequired,
	article: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default connector(SettingsRnd, (props) => ({
	article: props.article,
	sentence: props.sentence,
	user: props.user,
	settingsVisible: props.settingsVisible,
	player: props.player
}));
