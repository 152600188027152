import * as constants from "../constants";

export const isArticleSentence = (sentence) => {
    return !!sentence?.ArticleId;
};

export const getGuideById = (article, guideId) => {
    return article.Guides.find(
        (p) => p.Id === guideId
    )
}

export const getCurrentSentences = (article, sentence) => {
    if (isArticleSentence(sentence)) {
        if (article.SentencesCurrentVersion) {
            return article.SentencesCurrentVersion;
        }
    } else {
        const guide = getGuideById(article, sentence.GuideId);
        if (guide?.GuideSentences) {
            return guide.GuideSentences;
        }
    }

    return [];
}

/**
 *
 * @param sentenceSource
 * @param article
 * @returns {Readonly<{}>}
 */
export const getEntityStates = (sentenceSource, article) => {
    if (article?.Id === sentenceSource) {
        return constants.ARTICLE_STATE;
    }
    return constants.GUIDE_STATE;
}