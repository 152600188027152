import React, {Component} from "react";
import PropTypes from "prop-types";
import {Dropdown, Menu, Input, Form} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {connector} from "../../store";

import RndWrapper from "../rndWrapper";
import Loader from "../loader";

import "./index.css";
import 'antd/dist/antd.css';
import ManuscriptsFooter from "../manuscriptsFooter";
import * as constants from "../../constants";
import {isAdmin, isGuideNarrator} from "../../helpers/authHelpers";

const {TextArea} = Input;


class ManuscriptsRnd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeManuscriptId: null,
            manuscriptEditing: false,
            fields: [
                {
                    name: ['manuscriptContent'],
                    value: '',
                },
            ]
        };
    }

    componentDidMount() {
        this._setRndDefaults();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {article} = this.props;
        const {activeManuscriptId} = this.state;

        if (!activeManuscriptId && article && article.Guides.length > 0) {
            this._setActiveManuscriptId(article.Guides[0].Id);
            const guide = this._getGuideById(article.Guides[0].Id);
            this._setManuscriptContent(guide.Content)
        }
    };

    componentWillUnmount = () => {

    };

    _setActiveManuscriptId = (manuscriptId) => {
        this.setState({activeManuscriptId: manuscriptId});
    }

    _setManuscriptContent = (content) => {
        this.setState({
            fields: [
                {
                    name: ['manuscriptContent'],
                    value: content,
                },
            ]
        });
    }

    _setRndDefaults = () => {
        if (window.manuscriptsRnd) {
            window.manuscriptsRnd.loadPropertiesFromCookie();
        }
    };

    _selectManuscriptLanguage = (key) => {
        this._setActiveManuscriptId(key);
        this._cancelManuscriptEditing();
        const guide = this._getGuideById(key);
        this._setManuscriptContent(guide.Content)
    }

    _getGuideById = (guideId) => {
        const {article} = this.props;
        return article.Guides.find(
            (p) => p.Id === guideId
        )
    }

    _editManuscript = () => {
        const {action} = this.props;
        this.setState({manuscriptEditing: true});
        action.disablePlayer();
    }

    _cancelManuscriptEditing = () => {
        const {action} = this.props;
        this.setState({manuscriptEditing: false});
        action.enablePlayer();
    }

    _saveChanges = () => {
        const {fields, activeManuscriptId} = this.state;
        const {action} = this.props
        const content = fields[0].value;
        const currentGuide = this._getGuideById(activeManuscriptId);

        action.saveManuscript(currentGuide, content);
        this._cancelManuscriptEditing();
    }

    _confirmGuideManuscriptContent = () => {
        const {activeManuscriptId} = this.state;
        const {action} = this.props
        const currentGuide = this._getGuideById(activeManuscriptId);

        action.confirmGuideManuscriptContent(currentGuide);
    }

    _setFields = (fields) => {
        this.setState({fields});
    }

    _getGuidesForDropdown = () => {
        const {article} = this.props;

        if (!article?.Guides) return [];

        return article.Guides.filter((guide) =>
            guide.State >= constants.GUIDE_STATE.Manuscripting
            && guide.State !== constants.GUIDE_STATE.Disabled
        )
    }

    _shouldShowManuscript = () => {
        const {user, article} = this.props;
        return article?.GuidesEnabled && user && user.groups && (isAdmin(user) || isGuideNarrator(user))
    }

    render() {
        const {article, loading} = this.props;
        const {activeManuscriptId, manuscriptEditing, fields} = this.state;

        const guides = this._getGuidesForDropdown();
        const menu = (
            <Menu
                onClick={(e) => {
                    this._selectManuscriptLanguage(e.key);
                }}
            >
                {guides.length &&
                guides.map((g, i) => (
                    <Menu.Item key={g.Id}>{g.Language}</Menu.Item>
                ))}
            </Menu>);

        const currentGuide = this._getGuideById(activeManuscriptId);

        return (
            <div className="height-100">
                <RndWrapper
                    ref={(c) => {
                        window.manuscriptsRnd = c;
                    }}
                    isVisible={this._shouldShowManuscript()}
                    defaultWidth={400}
                    defaultHeight={Math.max(window.innerHeight / 2, 100)}
                    minHeight={200}
                    minWidth={340}
                    maxHeight={900}
                    maxWidth={700}
                    right={500}
                    top={250}
                    positionAsPercentage
                    className="manuscripts"
                    title="Manuscripts"
                    enableResizing
                    header={
                        <div className="height-100" />
                    }
                    content={
                        <div className="height-100">
                            {(loading.articleLoading || loading.guideLoading) && <Loader />}
                            {!loading.articleLoading && !loading.guideLoading && !guides.length && (
                                <div className="manuscript-container height-100">
                                    <div className="manuscript-content-container">
                                        <span className="empty-manuscript">
                                            There no guides to manuscript or all guides are disabled. <br />
                                            Please check the guides states. <br />
                                            <i>Guides with &quot;Manuscripting&quot; state or higher are supported.</i>
                                        </span>
                                    </div>
                                </div>
                            )}
                            {!loading.articleLoading && !loading.guideLoading && guides.length > 0 && (
                                <div className="manuscript-container height-100">
                                    <div className="manuscript-source-holder">
                                        <p>Current manuscript language:</p>
                                        <Dropdown overlay={menu} placement="bottomCenter">
                                            <button
                                                type="button"
                                                className="ant-dropdown-link"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                {currentGuide?.Language} <DownOutlined />
                                            </button>
                                        </Dropdown>
                                    </div>
                                    <div className={"manuscript-content-container " + currentGuide?.Language.toLowerCase()}>
                                        {!currentGuide?.Content && !manuscriptEditing && (
                                            <span
                                                className="empty-manuscript"
                                                onClick={(e) => this._editManuscript()}
                                                style={{display: "block"}}
                                            >
                                                Please click here to start <br /> writing the manuscript.
                                            </span>
                                        )}
                                        {currentGuide?.Content && !manuscriptEditing && (
                                            <div className="manuscript-content inactive">
                                                <span>{currentGuide.Content}</span>
                                            </div>
                                        )}
                                        {manuscriptEditing && (
                                            <div className="manuscript-content">
                                                <Form
                                                    name="manuscriptForm"
                                                    fields={fields}
                                                    onFieldsChange={(_, allFields) => {
                                                        this._setFields(allFields);
                                                    }}
                                                    scrollToFirstError
                                                >
                                                    <Form.Item
                                                        name="manuscriptContent"
                                                        rules={[]}
                                                    >
                                                        <TextArea name="manuscriptContent" />
                                                    </Form.Item>

                                                </Form>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    footer={
                        article?.Id && currentGuide?.Id && guides.length > 0 ? (
                            <div className="height-100">
                                <ManuscriptsFooter
                                    manuscriptEditing={manuscriptEditing}
                                    articleChangedSinceGuideManuscripting={currentGuide.ArticleGotChanges}
                                    saveChangesCallback={this._saveChanges}
                                    editManuscriptCallback={this._editManuscript}
                                    cancelEditCallback={this._cancelManuscriptEditing}
                                    confirmGuideManuscriptContent={this._confirmGuideManuscriptContent}
                                />
                            </div>
                        ) : null
                    }
                />
            </div>
        );
    }
}

ManuscriptsRnd.propTypes = {
    article: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    action: PropTypes.shape({
        saveManuscript: PropTypes.func.isRequired,
        disablePlayer: PropTypes.func.isRequired,
        enablePlayer: PropTypes.func.isRequired,
        confirmGuideManuscriptContent: PropTypes.func.isRequired
    }).isRequired
};

export default connector(ManuscriptsRnd, (props) => ({
    article: props.article,
    user: props.user,
    loading: props.loading,
    action: {
        saveManuscript: props.action.saveManuscript,
        disablePlayer: props.action.disablePlayer,
        enablePlayer: props.action.enablePlayer,
        confirmGuideManuscriptContent: props.action.confirmGuideManuscriptContent
    }
}));
