import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip-lite";
import { toast } from "react-toastify";
import WaveSurferAudio from "../../services/waveSurferAudio";
import * as constants from "../../constants";
import Loader from "../loader";
import { connector } from "../../store";
import {
	isNarrator,
	isAdmin,
	isAuthenticated, isGuideNarrator
} from "../../helpers/authHelpers";
import PrevButton from "../buttons/prev";
import NextButton from "../buttons/next";
import PauseButton from "../buttons/pause";
import PlayButton from "../buttons/play";
import StopRecordingButton from "../buttons/stopRecording";
import StartRecordingButton from "../buttons/startRecording";
import Toggle from "../toggle";
import "./index.css";
import RndWrapper from "../rndWrapper";
import * as logger from "../../helpers/logger";
import * as util from "../../helpers/util";
import RtcMediaRecorder from "../../services/rtcMediaRecorder";
import RecordingQueue from "../../services/recordingQueue";
import SettingsButton from "../buttons/settings";
import {GUID_EMPTY} from "../../constants";
import * as entityHelper from "../../helpers/entityHelper";
import {getCurrentSentences, getGuideById} from "../../helpers/entityHelper";

class PlayerRecorderRnd extends Component {
	constructor(props) {
		super(props);
		document.addEventListener("keydown", this._onKeyDown);
		this.state = {
			sentences: []
		};
		this.recordDelay = null;
	}

	componentDidMount() {
		const { action, user } = this.props;
		if (isAuthenticated(user)) {
			const audio = new WaveSurferAudio("#waveform");
			action.initAudio(audio);
			audio.addEventListener("ended", () => action.onAudioEnded());
			audio.addEventListener("ready", () =>
				action.hideLoader("setSelectedAudio")
			);

			const audioConstraints = util.getAudioConstraints();

			this.recorder = new RtcMediaRecorder(
				this._onRecordStart,
				this._onRecordStop,
				this._onRecordPause,
				this._onRecordResume,
				this._onRecording,
				this._onRecordError,
				audioConstraints === {} ? true : audioConstraints
			);

			this._recordingQueue = new RecordingQueue(action.saveSelectedSound);
		} else {
			action.initAudio();
		}

		this._setRndDefaults();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {sentence} = this.props;
		if (prevProps.sentence !== sentence) {
			this._setSentences();
		}
	}

	_onRecordError = (message, error) => {
		logger.error(message, error);
		toast.error(message + " See console for more information.", {
			autoClose: false
		});
	};

	_onRecording = (totalRecordedSeconds) => {
		const minutesLabel = document.getElementById("recorded-minutes");
		const secondsLabel = document.getElementById("recorded-seconds");
		secondsLabel.innerHTML = util.pad(totalRecordedSeconds % 60);
		minutesLabel.innerHTML = util.pad(parseInt(totalRecordedSeconds / 60));
	};

	_onRecordStart = (args) => {
		const { action } = this.props;
		action.startRecording();
	};

	_flushRecordingQueue = () => {
		const { action } = this.props;
		action.showLoader("saveSelectedSound");
		return this._recordingQueue
			.Flush()
			.then(() => {
				action.hideLoader("saveSelectedSound");
				return null;
			})
			.catch((error) => {
				logger.error(error, "Failed to save recording(s)");
				toast.error(
					"Failed to save recording(s)! See the console for more information.",
					{
						autoClose: false
					}
				);
			});
	};

	_onRecordStop = (blob, args) => {
		const { sentence, action, article } = this.props;
		const { sentences } = this.state;
		const { stepNext } = args;

		action.stopRecording();

		const isLastSentence =
			sentence.Order + 1 >= sentences.length;

		if (blob !== null) {
			const articleId = entityHelper.isArticleSentence(sentence) ? sentence.ArticleId : getGuideById(article, sentence.GuideId).ArticleId;
			const guideId = entityHelper.isArticleSentence(sentence) ? GUID_EMPTY : sentence.GuideId;
			this._recordingQueue.Add(
				blob,
				articleId,
				guideId,
				sentence.Order,
				this.recorder.GetRecordedSeconds()
			);
		} else {
			console.warn(
				`Not saving recording of sentence ${sentence.Order} into queue, blob is null`
			);
		}

		if (stepNext) {
			if (isLastSentence) {
				this._flushRecordingQueue().then(() => {
					this._selectNextSentence();
				});
			} else {
				this._selectNextSentence((nextSentence) => {
					if (nextSentence.Order > 0 && !isLastSentence) {
						logger.debug("Starting next recording");
						this._startRecording();
					}
				});
			}
		} else {
			this._flushRecordingQueue().then(() => {
				action.setSelectedAudio();
			});
		}
	};

	_onRecordPause = (args) => {
		const { action } = this.props;
		action.pauseRecording();
	};

	_onRecordResume = (args) => {
		const { action } = this.props;
		action.resumeRecording();
	};

	_startRecording = () => {
		const { player } = this.props;
		const audioConstraints = util.getAudioConstraints();
		this.recorder.SetAudioConstraints(audioConstraints);
		logger.debug("Current player status", player.status);
		if (
			!this._shouldAllowRecording() ||
			player.status === constants.PLAYER_STATUS.Playing ||
			player.status === constants.PLAYER_STATUS.AutoPlaying ||
			player.status === constants.PLAYER_STATUS.Recording
		) {
			return;
		}
		if (this.recordDelay !== null) {
			return;
		}
		this.recordDelay = window.setTimeout(() => {
			this.recordDelay = null;
		}, 1 * 1000);

		this.recorder.Start();
	};

	_stopRecording = (stepNext) => {
		stepNext = stepNext || false;
		this.recorder.Stop({ stepNext });
	};

	_shouldDisplayWaveform = () => {
		const { user, player, sentence } = this.props;
		if (!isAuthenticated(user)) return false;
		if (player.isLoading && player.status !== constants.PLAYER_STATUS.Recording)
			return false;
		if (player.isRecordingPaused) return false;
		if (
			(sentence.SentenceState === constants.SENTENCE_STATE.NotRecorded ||
				sentence.SentenceState === constants.SENTENCE_STATE.Changed) &&
			player.status !== constants.PLAYER_STATUS.Recording
		)
			return false;
		return true;
	};

	_shouldAllowRecording = () => {
		const { sentence, user} = this.props;
		if (!isAdmin(user) && !isNarrator(user) && !isGuideNarrator(user)) return false;
		if (!(sentence.ArticleId || sentence.GuideId)) return false;
		if (sentence.SentenceState === constants.SENTENCE_STATE.Disabled)
			return false;

		return this._isEntityReadyForRecording();
	};

	_shouldShowRecorder = () => {
		const {sentence, user, article, player} = this.props;
		if (!isAuthenticated(user)) return false;
		if (!article.Id || !(sentence.ArticleId || sentence.GuideId)) return false;

		if (sentence.SentenceState === constants.SENTENCE_STATE.Disabled)
			return false;
		if (player.disableRecorder) return false;
		if (entityHelper.isArticleSentence(sentence)) {
			if (article.State === constants.ARTICLE_STATE.New) return false;
			if (article.State === constants.ARTICLE_STATE.Disabled) return false;
		} else if (
			getGuideById(article, sentence.GuideId).State === constants.GUIDE_STATE.New
			|| getGuideById(article, sentence.GuideId).State === constants.GUIDE_STATE.Manuscripting
		) return false;

		return true;
	};

	_playPrevSentence = () => {
		const {sentence, player, action} = this.props;
		const {sentences} = this.state;

		if (player.status === constants.PLAYER_STATUS.Recording) {
			toast.error("Please stop the recording before selecting a new sentence");
			return;
		}

		if (sentences.length && (sentence?.ArticleId || sentence?.GuideId)) {
			for (let order = sentence.Order - 1; order >= 0; order--) {
				const testSentence = sentences[order];
				if (testSentence.SentenceState !== constants.SENTENCE_STATE.Disabled) {
					action.setSelectedSentence(testSentence, false, null);
					return;
				}
			}
			action.setSelectedSentence(
				sentences[
					sentences.length - 1
				],
				false,
				null
			);
		}
	};

	_selectNextSentence = (cb) => {
		const {sentence, player, action} = this.props;
		const {sentences} = this.state;
		if (player.status === constants.PLAYER_STATUS.Recording) {
			logger.warn("Please stop the recording before selecting a new sentence");
			toast.error("Please stop the recording before selecting a new sentence");
			return;
		}

		if (sentences.length && (sentence?.ArticleId || sentence?.GuideId)) {
			for (
				let order = sentence.Order + 1;
				order < sentences.length;
				order++
			) {
				const testSentence = sentences[order];
				if (testSentence.SentenceState !== constants.SENTENCE_STATE.Disabled) {
					action.setSelectedSentence(testSentence, false, cb);
					return;
				}
			}
			action.setSelectedSentence(sentences[0], false, cb);
		}
	};

	_onKeyDown = (e) => {
		const { action, player } = this.props;

		if (player.disableRecorder) {
			return;
		}

		Object.keys(constants.PLAYER_KEYS).forEach((actionName) => {
			const playerKey = constants.PLAYER_KEYS[actionName];
			if (
				e.code === playerKey.keyCode &&
				(e.ctrlKey || e.shiftKey || !playerKey.secondary)
			) {
				if (playerKey.preventDefault) {
					e.preventDefault();
				}
				switch (playerKey) {
					case constants.PLAYER_KEYS.TogglePlayback:
						const remarkInput = document.querySelector(
							".remark-input textarea"
						);
						if (remarkInput !== document.activeElement) {
							e.preventDefault();
							this._onButtonTogglePlayback();

							if (
								player?.status === constants.PLAYER_STATUS.Playing ||
								player?.status === constants.PLAYER_STATUS.AutoPlaying
							) {
								if (!e.ctrlKey && !e.metaKey) {
									remarkInput.focus();
									logger.debug("player status", player?.status);
								}
							}
						}
						break;
					case constants.PLAYER_KEYS.PrevSentence:
						this._playPrevSentence();
						break;
					case constants.PLAYER_KEYS.NextSentence:
						this._selectNextSentence();
						break;
					case constants.PLAYER_KEYS.ChangePlaybackRateUp:
						action.changePlaybackRateUp();
						break;
					case constants.PLAYER_KEYS.ChangePlaybackRateDown:
						action.changePlaybackRateDown();
						break;
					case constants.PLAYER_KEYS.RecordNextSentence:
						this._onButtonRecordNextSentence();
						break;
					case constants.PLAYER_KEYS.ToggleRecord:
						this._onButtonToggleRecord();
						break;
					case constants.PLAYER_KEYS.PauseResumeRecord:
						this._onButtonPauseResumeRecord();
						break;
					default:
						console.warn("Key action not implemented: " + playerKey);
				}
			}
		});
	};

	_onButtonSettings = () => {
		if (window.settingsRnd) {
			window.settingsRnd.Show();
		}
	};

	/**
	 * @returns {boolean}
	 * @private
	 */
	_isEntityReadyForRecording = () => {
		const {sentence, article} = this.props;
		if (entityHelper.isArticleSentence(sentence)) {
			return util.isArticleRecording(article.State);
		}
		const guide = getGuideById(article,sentence.GuideId);
		return util.isGuideRecording(guide.State);
	}

	_onButtonToggleRecord = () => {
		const {player} = this.props;
		if (!this._isEntityReadyForRecording()) return;

		if (player.status === constants.PLAYER_STATUS.Recording) {
			this._stopRecording();
		} else {
			this._startRecording();
		}
	};

	_onButtonPauseResumeRecord = () => {
		const {player} = this.props;
		if (!this._isEntityReadyForRecording()) return;
		if (player.status === constants.PLAYER_STATUS.Recording) {
			if (!player.isRecordingPaused) {
				this.recorder.Pause();
			} else {
				this.recorder.Resume();
			}
		}
	};

	_onButtonRecordNextSentence = () => {
		const {player} = this.props;
		if (!this._isEntityReadyForRecording()) return;
		if (
			this.recorder.GetRecordedSeconds() > 0 &&
			player.status === constants.PLAYER_STATUS.Recording
		) {
			this._stopRecording(true);
		}
	};

	_onButtonTogglePlayback = () => {
		const { player, action } = this.props;
		switch (player.status) {
			case constants.PLAYER_STATUS.Recording:
				this._stopRecording();
				break;
			case constants.PLAYER_STATUS.Playing:
			case constants.PLAYER_STATUS.AutoPlaying:
				action.pause();
				break;
			default:
				action.play();
		}
	};

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this._onKeyDown);
		if (this.recorder) {
			this.recorder.Destroy();
		}
	};

	_setRndDefaults = () => {
		if (window.recorderRnd) {
			window.recorderRnd.loadPropertiesFromCookie();
		}
	};

	_setSentences = () => {
		const {sentence, article} = this.props;
		if (sentence) {
			this.setState({sentences: getCurrentSentences(article, sentence)});
		}
	}

	render = () => {
		const { sentence, player, action } = this.props;
		const {sentences} = this.state;
		const sentencesLength = sentences.length;

		return (
			<div className="height-100">
				<RndWrapper
					ref={(c) => {
						window.recorderRnd = c;
					}}
					isVisible={this._shouldShowRecorder()}
					defaultWidth={500}
					defaultHeight={168}
					right={500}
					top={50}
					positionAsPercentage
					className="playerRecorder"
					title={this._shouldAllowRecording() ? "Recorder" : "Player"}
					header={
						<div className="height-100">
							<div className="player-buttons">
								<div style={{ float: "left" }}>
									<Tooltip
										content={
											"Previous sentence " +
											constants.PLAYER_KEYS.PrevSentence.text
										}
										direction="up"
									>
										<PrevButton
											selectedSentence={sentence}
											onClick={() => this._playPrevSentence()}
										/>
									</Tooltip>
								</div>
								<div style={{ float: "left" }}>
									<Tooltip
										content={
											"Next sentence " + constants.PLAYER_KEYS.NextSentence.text
										}
										direction="up"
									>
										<NextButton
											selectedSentence={sentence}
											onClick={() => this._selectNextSentence()}
											foundSentencesLength={sentencesLength}
										/>
									</Tooltip>
								</div>

								<div style={{ float: "left" }}>
									{(player.status === constants.PLAYER_STATUS.Playing ||
										player.status === constants.PLAYER_STATUS.AutoPlaying) && (
										<Tooltip
											content={
												"Pause " + constants.PLAYER_KEYS.TogglePlayback.text
											}
											direction="up"
										>
											<PauseButton onClick={this._onButtonTogglePlayback} />
										</Tooltip>
									)}
								</div>
								<div style={{ float: "left" }}>
									{player.status !== constants.PLAYER_STATUS.Playing &&
										player.status !== constants.PLAYER_STATUS.AutoPlaying &&
										player.status !== constants.PLAYER_STATUS.Recording && (
											<Tooltip
												content={
													"Play " + constants.PLAYER_KEYS.TogglePlayback.text
												}
												direction="up"
											>
												<PlayButton onClick={this._onButtonTogglePlayback} />
											</Tooltip>
										)}
								</div>
								<div style={{ float: "left", marginRight: "7px" }}>
									<Tooltip
										content={
											"Playback rate " +
											constants.PLAYER_KEYS.ChangePlaybackRateUp.text
										}
										direction="up"
									>
										<span
											style={{
												fontSize: "14px",
												fontWeight: "bold",
												cursor: "pointer"
											}}
											onClick={action.changePlaybackRateToggle}
										>
											x{player.playbackRate === 1 ? "1.0" : player.playbackRate}
										</span>
									</Tooltip>
								</div>
								<div
									style={{
										float: "left",
										display: this._shouldAllowRecording() ? "block" : "none"
									}}
								>
									{player.status === constants.PLAYER_STATUS.Recording &&
										!player.isRecordingPaused && (
											<Tooltip
												content={
													"Pause recording " +
													constants.PLAYER_KEYS.PauseResumeRecord.text
												}
												direction="up"
											>
												<PauseButton
													onClick={() => this._onButtonPauseResumeRecord()}
												/>
											</Tooltip>
										)}
								</div>
								<div
									style={{
										float: "left",
										display: this._shouldAllowRecording() ? "block" : "none"
									}}
								>
									{player.isRecordingPaused && (
										<Tooltip
											content={
												"Resume recording " +
												constants.PLAYER_KEYS.PauseResumeRecord.text
											}
											direction="up"
										>
											<StartRecordingButton
												onClick={() => this._onButtonPauseResumeRecord()}
											/>
										</Tooltip>
									)}
								</div>
								<div
									style={{
										float: "left",
										display: this._shouldAllowRecording() ? "block" : "none"
									}}
								>
									{player.status === constants.PLAYER_STATUS.Recording && (
										<Tooltip
											content={
												"Stop recording " +
												constants.PLAYER_KEYS.ToggleRecord.text
											}
											direction="up"
										>
											<StopRecordingButton
												onClick={() => this._onButtonToggleRecord()}
											/>
										</Tooltip>
									)}
								</div>
								<div
									style={{
										float: "left",
										display: this._shouldAllowRecording() ? "block" : "none"
									}}
								>
									{player.status !== constants.PLAYER_STATUS.Recording && (
										<Tooltip
											content={
												"Start recording " +
												constants.PLAYER_KEYS.ToggleRecord.text
											}
											direction="up"
										>
											<StartRecordingButton
												onClick={() => this._onButtonToggleRecord()}
												isPlaying={
													player.status === constants.PLAYER_STATUS.Playing ||
													player.status === constants.PLAYER_STATUS.AutoPlaying
												}
											/>
										</Tooltip>
									)}
									{player.status === constants.PLAYER_STATUS.Recording && (
										<div style={{ marginTop: "2px", fontSize: "14px" }}>
											<div
												style={{ paddingRight: "4px" }}
												className="record-blink fa fa-circle button button--start"
											/>
											<span>
												{player.isRecordingPaused ? "Paused at" : "Recording"}{" "}
											</span>
											<label id="recorded-minutes">00</label>:
											<label id="recorded-seconds">00</label>
										</div>
									)}
								</div>
								{player.status !== constants.PLAYER_STATUS.Recording && (
									<div style={{ float: "right" }}>
										<Tooltip content="Toggle auto play" direction="up">
											<Toggle
												toggleAction={() => action.toggleAutoPlay()}
												checked={player.autoPlay}
											/>
										</Tooltip>
									</div>
								)}
								{player.status !== constants.PLAYER_STATUS.Recording && (
									<div style={{ float: "right" }}>
										<Tooltip content="Recording settings" direction="up">
											<SettingsButton
												onClick={() => this._onButtonSettings()}
											/>
										</Tooltip>
									</div>
								)}
							</div>
						</div>
					}
					content={
						<div
							className={
								"height-100 " +
								(player.status === constants.PLAYER_STATUS.Recording
									? constants.PLAYER_STATUS.Recording
									: "")
							}
						>
							{<Loader className="player-loader" style={{ display: "none" }} />}
							{(sentence.SentenceState ===
								constants.SENTENCE_STATE.NotRecorded ||
								sentence.SentenceState === constants.SENTENCE_STATE.Changed) &&
								player.status !== constants.PLAYER_STATUS.Recording && (
									<div className="recording-info">
										This sentence is not yet recorded
									</div>
								)}
							<div
								style={{
									display: this._shouldDisplayWaveform() ? "block" : "none"
								}}
								id="waveform"
							/>
						</div>
					}
				/>
			</div>
		);
	};
}

PlayerRecorderRnd.propTypes = {
	article: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	sentence: PropTypes.object.isRequired,
	player: PropTypes.object.isRequired,
	action: PropTypes.shape({
		initAudio: PropTypes.func.isRequired,
		hideLoader: PropTypes.func.isRequired,
		showLoader: PropTypes.func.isRequired,
		setSelectedSentence: PropTypes.func.isRequired,
		pause: PropTypes.func.isRequired,
		play: PropTypes.func.isRequired,
		changePlaybackRateToggle: PropTypes.func.isRequired,
		changePlaybackRateUp: PropTypes.func.isRequired,
		changePlaybackRateDown: PropTypes.func.isRequired,
		startRecording: PropTypes.func.isRequired,
		stopRecording: PropTypes.func.isRequired,
		pauseRecording: PropTypes.func.isRequired,
		resumeRecording: PropTypes.func.isRequired,
		saveSelectedSound: PropTypes.func.isRequired,
		setSelectedAudio: PropTypes.func.isRequired,
		selectFirstRelevantSentence: PropTypes.func.isRequired,
		onAudioEnded: PropTypes.func.isRequired,
		toggleAutoPlay: PropTypes.func.isRequired
	}).isRequired
};

export default connector(PlayerRecorderRnd, (props) => ({
	article: props.article,
	user: props.user,
	sentence: props.sentence,
	player: props.player,
	action: {
		initAudio: props.action.initAudio,
		hideLoader: props.action.hideLoader,
		showLoader: props.action.showLoader,
		setSelectedSentence: props.action.setSelectedSentence,
		pause: props.action.pause,
		play: props.action.play,
		changePlaybackRateToggle: props.action.changePlaybackRateToggle,
		changePlaybackRateUp: props.action.changePlaybackRateUp,
		changePlaybackRateDown: props.action.changePlaybackRateDown,
		startRecording: props.action.startRecording,
		stopRecording: props.action.stopRecording,
		pauseRecording: props.action.pauseRecording,
		resumeRecording: props.action.resumeRecording,
		saveSelectedSound: props.action.saveSelectedSound,
		onAudioEnded: props.action.onAudioEnded,
		setSelectedAudio: props.action.setSelectedAudio,
		selectFirstRelevantSentence: props.action.selectFirstRelevantSentence,
		toggleAutoPlay: props.action.toggleAutoPlay
	}
}));
