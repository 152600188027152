const loadingReducer = (
	state = {
		articleLoading: false,
		guideLoading: false
	},
	action
) => {
	switch (action.type) {
		case "FETCHING_ARTICLE":
			return {
				...state,
				articleLoading: true
			};
		case "SET_ARTICLE":
			return {
				...state,
				articleLoading: false
			};
		case "FETCHING_GUIDE":
		case "FETCHING_GUIDES":
		case "FETCHING_GUIDE_RECORDINGS":
			return {
				...state,
				guideLoading: true
			};
		case "SET_GUIDE":
		case "SET_GUIDES":
		case "SET_GUIDE_RECORDINGS":
			return {
				...state,
				guideLoading: false
			};
		case "SET_ERRORS":
			return {
				...state,
				articleLoading: false
			};
		case "CLEAR_DATA":
			return { articleLoading: false };
		case "DISABLING_GUIDES":
		case "ENABLING_GUIDES":
			return {
				...state,
				articleLoading: false
			};
		default:
			return state;
	}
};
export default loadingReducer;
