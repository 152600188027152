/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import "./index.css";

export default function Loader(props) {
	const { spinner, className } = props;
	return (
		<div
			className={classNames("Loader", className, {
				"Loader--default": !spinner,
				"Loader--spinner": spinner
			})}
		>
			<p className="loading-text">Loading</p>
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
			<div className="Loader-dot" />
		</div>
	);
}
