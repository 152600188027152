import * as constants from "../../constants";
import * as cookies from "../../helpers/cookies";

const defaultState = {
	status: constants.PLAYER_STATUS.Stopped,
	playbackRate: cookies.hasCookie("player.playback_rate")
		? parseFloat(cookies.getCookie("player.playback_rate"))
		: constants.PLAYBACK_RATE.default,
	recordings: {},
	isAudioInitialized: false,
	isLoading: false,
	autoPlay: !(cookies.getCookie("player.auto_play") === "0"),
	disableRecorder: false

};
const playerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case "PAUSE":
			return {
				...state,
				status: constants.PLAYER_STATUS.Paused
			};
		case "PLAY":
			return {
				...state,
				status: constants.PLAYER_STATUS.Playing
			};
		case "AUTO_PLAY":
			return {
				...state,
				status: constants.PLAYER_STATUS.AutoPlaying
			};
		case "RESET_PLAYER":
			return {
				...state,
				isLoading: false
			};
		case "STOP":
			return {
				...state,
				status: constants.PLAYER_STATUS.Stopped
			};
		case "LOAD":
			return {
				...state,
				status: constants.PLAYER_STATUS.Loading
			};
		case "INIT_AUDIO":
			return {
				...state,
				isAudioInitialized: true
			};
		case "DISABLE_RECORDER":
			return {
				...state,
				disableRecorder: true
			};
		case "ENABLE_RECORDER":
			return {
				...state,
				disableRecorder: false
			};
		case "CHANGE_PLAYBACK_RATE":
			return {
				...state,
				playbackRate: action.data.playbackRate
			};
		case "SET_RECORDING_STATUS":
			return {
				...state,
				status: action.data.recording
					? constants.PLAYER_STATUS.Recording
					: constants.PLAYER_STATUS.Stopped,
				isRecordingPaused: !!action.data.isRecordingPaused
			};
		case "POST_SOUND_REQUEST":
			return {
				...state,
				isLoading: true
			};
		case "POST_GUIDE_SOUND_SUCCESS":
		case "POST_SOUND_SUCCESS":
			return {
				...state,
				recordings: {
					...state.recordings,
					...{
						[action.data.recordingId]: {
							Url: action.data.Url
						}
					}
				},
				isLoading: false
			};
		case "SET_RECORDINGS":
		case "SET_GUIDE_RECORDINGS":
			return {
				...state,
				recordings: {
					...action.recordings
				},
				latestFetch: action.time
			};
		case "SET_SELECTED_AUDIO_REQUEST":
			return {
				...state,
				isLoading: true
			};
		case "SET_SELECTED_AUDIO_SUCCESS":
			return {
				...state,
				isLoading: false
			};
		case "TOGGLE_AUTO":
			return {
				...state,
				autoPlay: !state.autoPlay
			};
		case "CLEAR_DATA":
			return defaultState;
		default:
			return state;
	}
};
export default playerReducer;
