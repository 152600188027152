import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";

export default class Toggle extends Component {
	guidGenerator = () => {
		const S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return (
			S4() +
			S4() +
			"-" +
			S4() +
			"-" +
			S4() +
			"-" +
			S4() +
			"-" +
			S4() +
			S4() +
			S4()
		);
	};

	render() {
		const { title, toggleAction, checked, id } = this.props;
		const uniqid = this.guidGenerator();
		return (
			<div className="tgl-wrap" title={title}>
				<input
					id={id || uniqid}
					className="tgl tgl-light"
					type="checkbox"
					onChange={toggleAction}
					checked={checked}
				/>
				<label aria-label="Toggle" className="tgl-btn" htmlFor={id || uniqid} />
			</div>
		);
	}
}

Toggle.propTypes = {
	toggleAction: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	title: PropTypes.string,
	id: PropTypes.string
};

Toggle.defaultProps = {
	title: "",
	id: null
};
