import PropTypes from "prop-types";
import RecordingQueueItem from "./recordingQueueItem";
import * as logger from "../helpers/logger";
import * as util from "../helpers/util";
import {GUID_EMPTY} from "../constants";
import RecordingQueueGuideItem from "./recordingQueueGuideItem";

export default class RecordingQueue {
	constructor(postAction) {
		const defaultAction = (blob, articleId, sentenceOrder, recordedSeconds) => {
			return new Promise((resolve, reject) => {
				logger.debug(
					"(Default post)",
					articleId,
					sentenceOrder,
					recordedSeconds
				);
				resolve();
			});
		};
		this._init(postAction || defaultAction);
	}

	_init = (postAction) => {
		this._queue = [];
		this._postAction = postAction;
	};

	Add = (blob, articleId, guideId, sentenceOrder, recordedSeconds) => {
		let queueItem;
		if (GUID_EMPTY === guideId) {
			queueItem = new RecordingQueueItem(
				blob,
				articleId,
				sentenceOrder,
				recordedSeconds
			);
		} else {
			queueItem = new RecordingQueueGuideItem(
				blob,
				articleId,
				guideId,
				sentenceOrder,
				recordedSeconds
			);
		}

		this._queue.push(queueItem);
		logger.debug("Adding recording queue item: " + queueItem.toString());
	};

	Clear = () => {
		this._queue = [];
	};

	_postRecording = (queueItem) => {
		return this._postAction(
			queueItem.Blob,
			queueItem.ArticleId,
			queueItem.GuideId ? queueItem.GuideId : GUID_EMPTY,
			queueItem.SentenceOrder,
			queueItem.RecordedSeconds
		).catch((e) => {
			logger.error(e, "Failed to save recording: " + queueItem.toString());
			throw new Error("Failed to save recording: " + queueItem.toString());
		});
	};

	Flush = () => {
		logger.debug(
			"Trying to post " + this._queue.length + " recording queue items",
			this._queue
		);
		return new Promise((resolve, reject) => {
			const batchOptions = {
				batchSize: 5,
				retry: false
			};
			util
				.batchPromises(this._queue, this._postRecording, batchOptions)
				.then((items) => {
					this.Clear();
					return resolve();
				})
				.catch((e) => {
					logger.error(e, "Failed to save recording(s)");
					reject(Error(e));
				});
		});
	};
}

RecordingQueue.propTypes = {
	postAction: PropTypes.func.isRequired
};
