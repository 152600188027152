import React from "react";
import PropTypes from "prop-types";
import "./index.css";

export default function Select(props) {
	const { tooltip, value, values, inputProps } = props;
	return (
  <span data-tooltip={tooltip}>
    <select
      className="select"
      value={value || values[0].value || values[0].key}
      onChange={event => props.onChange(event.target.value, inputProps)}
    >
      {values.map(v => (
        <option value={v.value || v.key} key={v.key}>
          {v.key}
        </option>
				))}
    </select>
  </span>
	);
}

Select.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.number,
	tooltip: PropTypes.string,
	values: PropTypes.array,
	inputProps: PropTypes.object
};

Select.defaultProps = {
	onChange: () => {},
	value: 1,
	inputProps: {},
	values: [],
	tooltip: ""
};
