import * as constants from "../../constants";

const sentenceReducer = (state = {}, action) => {
	switch (action.type) {
		case "SET_SELECTED_SENTENCE":
			return action.sentence || state;
		case "POST_SOUND_SUCCESS":
		case "POST_GUIDE_SOUND_SUCCESS":
			return {
				...state,
				RecordingId: action.data.recordingId,
				SentenceState: constants.SENTENCE_STATE.Recorded,
				Remark: "",
				RemarkUpdated: new Date(),
				_state: constants.SENTENCE_STATE.Recorded
			};
		case "SAVE_REMARK":
		case "SAVE_GUIDE_SENTENCE_REMARK":
			return {
				...state,
				Remark: action.remark
			};
		case "ENABLE_SENTENCE":
			const isRecorded = action.sentence.RecordingId !== constants.GUID_EMPTY;
			if (isRecorded) {
				return {
					...state,
					_state: constants.SENTENCE_STATE.Recorded,
					SentenceState: constants.SENTENCE_STATE.Recorded
				};
			}
			return {
				...state,
				_state: constants.SENTENCE_STATE.NotRecorded,
				SentenceState: constants.SENTENCE_STATE.NotRecorded
			};

		case "DISABLE_SENTENCE":
			return {
				...state,
				_state: constants.SENTENCE_STATE.Disabled,
				SentenceState: constants.SENTENCE_STATE.Disabled
			};
		case "CLEAR_DATA":
			return {};
		case "SET_URL":
			return {};
		default:
			return state;
	}
};
export default sentenceReducer;
