export default request => ({
  postRecording(articleId, sentenceOrder, lengthSeconds, formData, cb) {
    return request({
      path: `articles/${articleId}/sentences/${sentenceOrder}/record?lengthSeconds=${lengthSeconds}`,
      method: 'post',
      body: formData
    })
  },
  postGuideRecording(articleId, guideId, sentenceOrder, lengthSeconds, formData, cb) {
    return request({
      path: `articles/${articleId}/guides/${guideId}/sentences/${sentenceOrder}/record?lengthSeconds=${lengthSeconds}`,
      method: 'post',
      body: formData
    })
  }
})
