export default (request) => ({
    enableGuides(articleId) {
        if (!articleId) {
            throw new Error("Cannot enable guides, article ID not set");
        }

        return request({
            path: `articles/enableGuides?articleId=${articleId}`,
            method: "patch"
        });
    },
    postManuscript(articleId, guideId, manuscript) {
        return request({
            path: `articles/${articleId}/guides/${guideId}/manuscript`,
            method: 'patch',
            body: manuscript
        })
    },
    confirmManuscript(articleId, guideId) {
        return request({
            path: `articles/${articleId}/guides/${guideId}/manuscript/confirm`,
            method: 'patch',
        })
    },
    fetchGuide(articleId, guideId) {
        return request({
            path: `articles/${articleId}/guides/${guideId}`,
            method: 'get'
        })
    },
    fetchGuides(articleId) {
        return request({
            path: `articles/${articleId}/guides`,
            method: 'get'
        })
    },
    getAudio(articleId, guideId) {
        if (!articleId) {
            throw new Error("Cannot fetch recordings, guide's article ID not set");
        }
        if (!guideId) {
            throw new Error("Cannot fetch recordings, guide ID not set");
        }
        return request({
            path: `articles/${articleId}/guides/${guideId}/audio`,
            method: "get"
        });
    },
    getWorkedTime(articleId, guideId) {
        if (!articleId) {
            throw new Error("Cannot get worked time, article ID not set");
        }
        if (!guideId) {
            throw new Error("Cannot get worked time, guide ID not set");
        }
        return request({
            path: `articles/${articleId}/guides/${guideId}/worktime`,
            method: "get"
        });
    },
    recordingsCompleted(articleId, guideId) {
        if (!articleId) {
            throw new Error("Cannot set recordings completed, article ID not set");
        }
        if (!guideId) {
            throw new Error("Cannot set recordings completed, guide ID not set");
        }
        return request({
            path: `articles/${articleId}/guides/${guideId}/recordingsCompleted`,
            method: "patch"
        });
    },
    proofListenerCompleted(articleId, guideId) {
        if (!articleId) {
            throw new Error("Cannot set proof listening completed, article ID not set");
        }
        if (!guideId) {
            throw new Error("Cannot set proof listening completed, guide ID not set");
        }
        return request({
            path: `articles/${articleId}/guides/${guideId}/proofListenerCompleted`,
            method: "patch"
        });
    },
});
