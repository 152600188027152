import React, { Component } from "react";
import {
	Authenticator,
	SignIn,
	ConfirmSignIn,
	VerifyContact,
	ForgotPassword,
	RequireNewPassword
} from "aws-amplify-react";

import Amplify from "aws-amplify";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContainer from "./components/appContainer";
import { isAuthenticated } from "./helpers/authHelpers";
import { connector } from "./store";
import awsExports from "./awsExports";
import "./App.css";
import * as logger from "./helpers/logger";
import "@aws-amplify/ui/dist/style.css";
import {getCleanedURL} from "./helpers/util";

Amplify.configure(awsExports);
toast.configure();

const CustomTheme = {
	sectionHeader: {
		backgroundImage: `url(${
			process.env.REACT_APP_ILT_FRONTEND_URL + "/logo.jpg"
		})`,
		height: "100px",
		width: "100%",
		backgroundSize: "200px 100px",
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "center",
		paddingTop: "120px",
		paddingBottom: "20px"
	},
	a: { color: "#337ab7" },
	button: { backgroundColor: "#337ab7", color: "#fff" },
	navButton: { backgroundColor: "#337ab7", color: "#fff" },
	formSection: {
		boxShadow: "0 5px 15px rgba(0,0,0,.5)"
	}
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUrl: window.location.href,
			urlCheckInterval: setInterval(this.checkUrlChange, 500)
		};
	}

	componentDidMount() {
		if (
			process.env.REACT_APP_ENV &&
			process.env.REACT_APP_ENV.startsWith("dev")
		) {
			toast.success("ILT Overlay module loaded.");
		}
	}

	componentWillUnmount() {
		const { urlCheckInterval } = this.state;
		clearInterval(urlCheckInterval);
	}

	checkUrlChange = () => {
		const { action } = this.props;
		const { currentUrl } = this.state;
		const windowUrl = getCleanedURL();
		if (windowUrl !== currentUrl) {
			this.setState({
				currentUrl: windowUrl
			});
			action.setUrl(windowUrl);
			action
				.getArticle(windowUrl)
				.then((data) => {
					action.getRecordings(data?.Id);
					return data;
				})
				.catch((err) => {
					logger.error(err, "Failed to get article by url");
				});
		}
	};

	render() {
		const { action } = this.props;
		return (
			<div className="appContainer">
				<div className="overlay"></div>
				<div className="app">
					<Authenticator
						theme={CustomTheme}
						onStateChange={(authState) => {
							action.clearData(authState);
							if (authState === "signedIn") {
								document
									.querySelector("#dtmn-application .overlay")
									.classList.remove("active");
								action
									.getUser()
									.then((user) => {
										if (isAuthenticated(user)) {
											action.setUrl(getCleanedURL());
											return action
												.getArticle(getCleanedURL())
												.then((data) => {
													return data;
												})
												.catch((error) => {
													throw error;
												});
										}
										return null;
									})
									.catch((error) => {
										logger.error(error, "Something went wrong");
									});
							} else {
								document
									.querySelector("#dtmn-application .overlay")
									.classList.add("active");
							}
						}}
						amplifyConfig={awsExports}
						hideDefault
					>
						<SignIn />
						<ConfirmSignIn />
						<RequireNewPassword />
						<VerifyContact />
						<ForgotPassword />
						<AppContainer />
					</Authenticator>
				</div>
			</div>
		);
	}
}

App.propTypes = {
	action: PropTypes.shape({
		getUser: PropTypes.func.isRequired,
		getArticle: PropTypes.func.isRequired,
		getRecordings: PropTypes.func.isRequired,
		clearData: PropTypes.func.isRequired,
		setUrl: PropTypes.func.isRequired
	}).isRequired
};

export default connector(App, (props) => ({
	user: props.user,
	article: props.article,
	action: {
		getUser: props.action.getUser,
		getArticle: props.action.getArticle,
		getRecordings: props.action.getRecordings,
		clearData: props.action.clearData,
		setUrl: props.action.setUrl
	}
}));
