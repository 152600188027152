import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import "./confirm.css";
import { confirmAlert } from "react-confirm-alert";
import Tooltip from "react-tooltip-lite";
import {toast} from "react-toastify";
import { connector } from "../../store";
import { isAuthenticated } from "../../helpers/authHelpers";
import * as constants from "../../constants";
import * as util from "../../helpers/util";
import * as logger from "../../helpers/logger";
import {getEntityStates} from "../../helpers/entityHelper";

class SentencesFooter extends Component {
	constructor(props) {
		super(props);
		document.addEventListener("keydown", this._onKeyDown);

		this.state = {
			submitPrompt: false
		};
	}

	componentDidMount() {
		this._setSentenceSourceEntity();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {sentenceSource, sentence} = this.props;
		if (sentenceSource && prevProps.sentenceSource !== sentenceSource && !prevState.sentenceSourceEntity) {
			this._setSentenceSourceEntity();
		}
		if (sentenceSource && prevProps.sentence !== sentence) {
			this._setSentenceSourceEntity();
		}
	}

	_enableDownloadButton = () => {
		const {user, sentenceSource, article} = this.props;
		const {sentenceSourceEntity} = this.state;

		const entityName = this._getSentenceSourceEntityName();
		if (!isAuthenticated(user)) return [false, "Not authenticated"];
		if (!sentenceSourceEntity?.Id) return [false, "Invalid  " + entityName];

		const validStates = ['Published', 'ReadyForPublishing'];
		const entityStates = getEntityStates(sentenceSource, article);
		if (!validStates.map(p => entityStates[p]).includes(sentenceSourceEntity.State)) {
			return [false, `The ${entityName} is not in a valid state`];
		}

		return [true, null];
	};



	_enableDoneButton = () => {
		const {user, sentenceSource, article} = this.props;
		const {sentenceSourceEntity} = this.state;

		const entityName = this._getSentenceSourceEntityName();

		if (!isAuthenticated(user)) return [false, "Not authenticated"];
		if (!sentenceSourceEntity?.Id) return [false, "Invalid " + entityName];

		const entityStates = getEntityStates(sentenceSource, article);

		const validStates = ['Recording', 'ProofListening','RecordingChanges', 'CorrectionsNeeded'];

		if (!validStates.map(p => entityStates[p]).includes(sentenceSourceEntity.State)) {
			return [false, `The ${entityName} is not in a valid state`];
		}
		if (
			(['Recording','RecordingChanges'].map(p => entityStates[p]).includes(sentenceSourceEntity.State)) &&
			this._getSentenceSourceEntitySentences().some(
				(p) =>
					p.SentenceState !== constants.SENTENCE_STATE.Recorded &&
					p.SentenceState !== constants.SENTENCE_STATE.Disabled
			)
		) {
			return [false, "There are unrecorded sentences"];
		}

		return [true, null];
	};

	_downloadAudio = () => {
		const {action} = this.props;
		const {sentenceSourceEntity} = this.state;

		// TODO Add guides support.
		action.downloadAudio(sentenceSourceEntity.Id);
	};

	_submit = () => {
		const { action, article, sentenceSource } = this.props;
		const {sentenceSourceEntity} = this.state;
		const entityName = this._getSentenceSourceEntityName();
		const shouldBeEnabled = this._enableDoneButton();
		let title = "";
		if (shouldBeEnabled[0]) {
			const entityStates = getEntityStates(sentenceSource, article);

			const validStates = ['Recording', 'RecordingChanges', 'CorrectionsNeeded'];
			if (validStates.map(p => entityStates[p]).includes(sentenceSourceEntity.State)) {
				title = "Are you sure you want to submit recordings to review?";
			}
			if (['ProofListening'].map(p => entityStates[p]).includes(sentenceSourceEntity.State)) {
				if (
					this._getSentenceSourceEntitySentences().some(
						(p) => !util.isEmptyOrSpaces(p.Remark)
					)
				) {
					title =
						`Are you sure you want to send this ${entityName} back to the narrator for corrections?`;
				} else {
					title = `Are you sure you want to approve this ${entityName}?`;
				}
			}

			(() => {
				if (sentenceSourceEntity.Id === article.Id) {
					return action.getWorkedTime(sentenceSourceEntity.Id);
				}
				return action.getGuidesWorkedTime(sentenceSourceEntity.ArticleId, sentenceSourceEntity.Id)
			})().then((result) => {
				const message =
					`Your accumulated work time for this ${entityName} is ${result}`;

				this.setState({
					submitPrompt: true
				});

				return confirmAlert({
					title,
					message,
					afterClose: () => {
						this.setState({
							submitPrompt: false
						});
					},
					buttons: [
						{
							label: `Yes ${constants.ARTICLE_KEYS.SubmitArticleYes.text}`,
							onClick: () => {
								this._finalizeWork(false);
								this.setState({
									submitPrompt: false
								});
							}
						},
						{
							label: `Yes, and continue with next ${entityName} ${constants.ARTICLE_KEYS.SubmitArticleYesAndContinue.text}`,
							onClick: () => {
								this._finalizeWork(true);
								this.setState({
									submitPrompt: false
								});
							}
						},
						{
							label: "No (Esc)",
							onClick: () => {
								this.setState({
									submitPrompt: false
								});
							}
						}
					]
				});
			}).catch(error => {
				logger.error(error, `Failed to update ${entityName} status`);
				toast.error(
					`Failed to update ${entityName}! See console for more information.`
				);
				return Promise.reject(error);
			});
		}
	};

	_onKeyDown = (e) => {
		const {player} = this.props;
		const { submitPrompt } = this.state;

		if (player.disableRecorder) {
			return;
		}

		Object.keys(constants.ARTICLE_KEYS).forEach((actionName) => {
			const key = constants.ARTICLE_KEYS[actionName];
			if (
				e.code === key.keyCode &&
				(e.ctrlKey || e.shiftKey || !key.secondary)
			) {
				const remarkInput = document.querySelector(".remark-input textarea");
				if (remarkInput === document.activeElement) {
					return;
				}

				if (key.preventDefault) {
					e.preventDefault();
				}
				switch (key) {
					case constants.ARTICLE_KEYS.SubmitArticle:
						this._submit();
						break;
					case constants.ARTICLE_KEYS.SubmitArticleYes:
						if (submitPrompt) {
							this._finalizeWork(false);
							this.setState({
								submitPrompt: false
							});
						}
						break;
					case constants.ARTICLE_KEYS.SubmitArticleYesAndContinue:
						if (submitPrompt) {
							this._finalizeWork(true);
							this.setState({
								submitPrompt: false
							});
						}
						break;
					default:
						break;
				}
			}
		});
	};

	_finalizeWork = (redirectToNextArticle) => {
		const {sentenceSourceEntity} = this.state;
		const {action, article} = this.props;

		if (sentenceSourceEntity.Id === article.Id) {
			return action.setArticleDone(sentenceSourceEntity, redirectToNextArticle);
		}
		return action.setGuideDone(sentenceSourceEntity, redirectToNextArticle);
	}

	_setSentenceSourceEntity = () => {
		const {sentenceSource, article} = this.props;

		if (article?.Id === sentenceSource) {
			this.setState({
				sentenceSourceEntity: article
			})
		} else {
			const guide = this._getGuideById(sentenceSource);
			this.setState({
				sentenceSourceEntity: guide
			})
		}
	}

	_getGuideById = (guideId) => {
		const {article} = this.props;
		return article.Guides.find(
			(p) => p.Id === guideId
		)
	}

	_getSentenceSourceEntityName = () => {
		const {sentenceSource, isArticle} = this.props;

		if (sentenceSource) {
			if (isArticle) {
				return "guide";
			}
		}
		return "article";
	}

	_getSentenceSourceEntitySentences = () => {
		const {sentenceSourceEntity} = this.state;
		if (!sentenceSourceEntity) {
			return [];
		}

		if (sentenceSourceEntity.SentencesCurrentVersion) {
			return sentenceSourceEntity.SentencesCurrentVersion;
		}

		return sentenceSourceEntity.GuideSentences;
	}

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this._onKeyDown);
	};

	render() {
		const enableDoneButton = this._enableDoneButton();
		const enableDownloadButton = this._enableDownloadButton();
		return (
			<div className="footer-container">
				{enableDownloadButton[0] && (
					<button
						className="btn btn-block btn-primary button-done"
						onClick={this._downloadAudio}
						type="button"
					>
						Download audio
					</button>
				)}

				{!enableDownloadButton[0] && (
					<div>
						<span className="done-info">{enableDoneButton[1]}</span>
						<div style={{ float: "right" }}>
							<Tooltip
								content={`Submit ${this._getSentenceSourceEntityName()} ${constants.ARTICLE_KEYS.SubmitArticle.text}`}
								direction="up"
							>
								<button
									disabled={!enableDoneButton[0]}
									className="btn btn-block btn-primary button-done"
									onClick={this._submit}
									type="button"
								>
									Done
								</button>
							</Tooltip>
						</div>
					</div>
				)}
			</div>
		);
	}
}

SentencesFooter.defaultProps = {
	isArticle: true
};
SentencesFooter.propTypes = {
	article: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	sentence: PropTypes.object.isRequired,
	sentenceSource:PropTypes.string.isRequired,
	isArticle:PropTypes.bool,
	player: PropTypes.object.isRequired,
	action: PropTypes.shape({
		setArticleDone: PropTypes.func.isRequired,
		setGuideDone: PropTypes.func.isRequired,
		downloadAudio: PropTypes.func.isRequired,
		getWorkedTime: PropTypes.func.isRequired,
		getGuidesWorkedTime: PropTypes.func.isRequired
	}).isRequired
};

export default connector(SentencesFooter, (props) => ({
	article: props.article,
	user: props.user,
	sentence: props.sentence,
	player: props.player,
	action: {
		setArticleDone: props.action.setArticleDone,
		setGuideDone: props.action.setGuideDone,
		downloadAudio: props.action.downloadAudio,
		getWorkedTime: props.action.getWorkedTime,
		getGuidesWorkedTime: props.action.getGuidesWorkedTime
	}
}));
