import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Tooltip from "react-tooltip-lite";
import { toast } from "react-toastify";
import {Dropdown, Menu, Popconfirm, Radio} from "antd";
import {DownOutlined} from "@ant-design/icons";
import SentenceMarker from "../../services/sentenceMarker";
import { connector } from "../../store";
import {
	isAuthenticated,
	isAdmin,
	isNarrator, isGuideNarrator, isGuideProofListener
} from "../../helpers/authHelpers";
import LogoutButton from "../buttons/logout";
import SentencesFooter from "../sentencesFooter";
import ExclamationIcon from "../buttons/exclamationIcon";
import RndWrapper from "../rndWrapper";
import Toggle from "../toggle";
import Loader from "../loader";
import * as constants from "../../constants";
import * as util from "../../helpers/util";
import * as logger from "../../helpers/logger";

import "./index.css";
import 'antd/dist/antd.css';
import {getEntityStates, getGuideById} from "../../helpers/entityHelper";

const sentencesTabState = 'sentences';
const guidesTabState = 'guides';

class SentencesRnd extends Component {
	constructor(props) {
		super(props);
		document.addEventListener("keydown", this._onKeyDown);
		this.state = {
			tabPosition: sentencesTabState,
			isArticle: true,
			isLoading: true
		};
	}

	componentDidMount() {
		this._setRndDefaults();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.sentenceSource && !prevState.isArticle) {
			if (!getGuideById(nextProps.article, prevState.sentenceSource)) {
				const isArticle = !nextProps.article?.Guides?.length > 0;
				return {
					sentenceSource: null,
					isArticle
				};
			}
		}
		return null;
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { article, sentence, player, loading } = this.props;
		const {sentenceSource} = this.state;
		if (
			article?.SentencesCurrentVersion &&
			!prevProps.article?.SentencesCurrentVersion
		) {
			this._domContainerQuery = article.PublisherDomContainer.trim();

			if (!this.marker) {
				this._marker = new SentenceMarker(
					this._domContainerQuery,
					article.SentencesCurrentVersion
				);
			}
		}

		if (prevProps.loading &&
			(prevProps.loading.articleLoading !== loading.articleLoading ||
				prevProps.loading.guideLoading !== loading.guideLoading)
		) {
			this._setLoaderStatus();
		}

		if (!prevState.sentenceSource && !sentenceSource) {
			this._setDefaultSentencesSource();
		}


		if (
			player &&
			prevProps.player &&
			Object.keys(player.recordings).length > 0 &&
			Object.keys(prevProps.player.recordings).length === 0 &&
			article?.State === constants.ARTICLE_STATE.ProofListening &&
			this._articleHasUpdatedRecordings()
		) {
			toast.warn(
				"This article only includes a few updated sentences. Only proof-listen the updated sentences.",
				{
					autoClose: 20000,
					position: "top-center"
				}
			);
		}

		if (
			this._marker &&
			sentence &&
			"Order" in sentence &&
			sentence !== prevProps.sentence	&&
			article.Id === sentenceSource
		) {
			this._marker.EnsureSentenceIsMarked(sentence);
		}
	};

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this._onKeyDown);
		if (this.marker) this.marker.Destroy();
	};

	_setDefaultSentencesSource = () => {
		const {article, user} = this.props;

		if (article?.Id) {

			if (isGuideNarrator(user) || isGuideProofListener(user)) {
				if (article?.Guides?.length > 0) {
					return this._setSentencesSource(article.Guides[0].Id);
				}
			} else {
				return this._setSentencesSource(article.Id);
			}
		}
	}

	_changeTabPosition = (e) => {
		this.setState({ tabPosition: e.target.value });
	}

	_selectNextSentence = (cb) => {
		const { article, sentence, player, action } = this.props;
		if (player.status === constants.PLAYER_STATUS.Recording) {
			logger.warn("Please stop the recording before selecting a new sentence");
			toast.error("Please stop the recording before selecting a new sentence");
			return;
		}

		if (article?.SentencesCurrentVersion && sentence?.ArticleId) {
			for (
				let order = sentence.Order + 1;
				order < article.SentencesCurrentVersion.length;
				order++
			) {
				const testSentence = article.SentencesCurrentVersion[order];
				if (testSentence.SentenceState !== constants.SENTENCE_STATE.Disabled) {
					action.setSelectedSentence(testSentence, false, cb);
					return;
				}
			}
			action.setSelectedSentence(article.SentencesCurrentVersion[0], false, cb);
		}
	};

	_canToggleSentence = () => {
		const {sentenceSource} = this.state;
		const {sentence, user, article} = this.props;
		if (!isAdmin(user) && !isNarrator(user)) return false;
		if (!article.Id) return false;
		if (!sentenceSource) return false;
		const sentenceSourceEntity = this._getSentenceSourceEntity();

		if (sentenceSourceEntity.Id === article.Id && !sentence.ArticleId) return false;

		const validStates = ['Published', 'Disabled'];
		const entityStates = getEntityStates(sentenceSource, article);
		if (validStates.map(p => entityStates[p]).includes(sentenceSourceEntity.State)) return false;

		return true;
	};

	_shouldShowSentenceFooter = () => {
		const {sentenceSource} = this.state;
		const {user, article} = this.props;
		if (!isAuthenticated(user)) return false;
		if (!article?.Id) return false;
		if (!sentenceSource) return false;
		if (this._getSentences().length === 0) return false
		const sentenceSourceEntity = this._getSentenceSourceEntity();
		const validStates = [
			'Recording', 'ProofListening', 'RecordingChanges',
			'Published', 'ReadyForPublishing', 'CorrectionsNeeded'
		];
		const entityStates = getEntityStates(sentenceSource, article);
		if (!validStates.map(p => entityStates[p]).includes(sentenceSourceEntity.State)) return false;

		return true;
	};

	_onKeyDown = (e) => {
		const { action, player } = this.props;

		if (player.disableRecorder) {
			return;
		}

		Object.keys(constants.SENTENCE_KEYS).forEach((actionName) => {
			const key = constants.SENTENCE_KEYS[actionName];
			if (
				e.code === key.keyCode &&
				(e.ctrlKey || e.shiftKey || !key.secondary)
			) {
				const remarkInput = document.querySelector(".remark-input textarea");
				if (remarkInput === document.activeElement) {
					return;
				}

				if (key.preventDefault) {
					e.preventDefault();
				}
				switch (key) {
					case constants.SENTENCE_KEYS.EnableDisableSentence:
						if (this._canToggleSentence()) {
							action.toggleDeactivated(() => {
								this._selectNextSentence();
							});
						}
						break;
					default:
						break;
				}
			}
		});
	};

	_shouldShowSentenceList = () => {
		const { user } = this.props;
		if (!isAuthenticated(user)) return false;
		return true;
	};

	_setRndDefaults = () => {
		if (window.sentencesRnd) {
			window.sentencesRnd.loadPropertiesFromCookie();
		}
	};

	_onSentenceClick = (sentence) => {
		const { action } = this.props;
		action.setSelectedSentence(sentence, true);
	};

	_articleHasUpdatedRecordings = () => {
		const { player } = this.props;

		const hasArticleBeenPublishedBefore =
			player?.recordings &&
			Object.keys(player.recordings).some(
				(p) => player.recordings[p].IsProcessed
			);

		const hasUnProcessedRecordings =
			player?.recordings &&
			Object.keys(player.recordings).some(
				(p) => !player.recordings[p].IsProcessed
			);

		return hasArticleBeenPublishedBefore && hasUnProcessedRecordings;
	};

	_toggleEnabledGuides = (guidesEnabled) => {
		if (guidesEnabled) {
			return this.setState({showPopconfirm: true});
		}
		const {action} = this.props;
		action.toggleEnabledGuides();
	}

	_getIsArticleChangedSinceGuideManuscripting = () => {
		const {sentenceSource, isArticle} = this.state;

		if (isArticle) return false;

		const guide = this._getGuideById(sentenceSource);
		return guide.ArticleGotChanges;
	}

	_setLoaderStatus = () => {
		const {loading} = this.props;
		if(loading.articleLoading || loading.guideLoading){
			return this.setState({
				isLoading: true
			})
		}

		return  this.setState({
			isLoading: false
		})
	}

	_setSentencesSource = async (key, cb) => {
		const {article, action} = this.props;
		if (key === article.Id) {
			this.setState({
				sentenceSource: key,
				isArticle: true,
				isLoading: true
			})
			await action.getRecordings(key);
			this._marker.MarkSentences();
			this.setState({
				isLoading: false
			})
		} else {
			const guide = this._getGuideById(key)

			this.setState({
				sentenceSource: key,
				isArticle: false,
				isLoading: true
			})
			if (guide.ArticleGotChanges) {
				this.setState({
					isLoading: false
				});

				return;
			}
			await action.fetchGuide(guide.ArticleId, guide.Id);
			await action.getGuideRecordings(guide.ArticleId, guide.Id);
			this.setState({
				isLoading: false
			})
		}

		this._setFirstSentence(key);

		if (typeof cb === "function") cb();
	}

	_getGuideById = (guideId) => {
		const {article} = this.props;
		return article.Guides.find(
			(p) => p.Id === guideId
		)
	}

	_getSentences = () => {
		const {sentenceSource, isArticle} = this.state;
		const {article} = this.props;
		if (sentenceSource) {
			if (isArticle) {
				return article.SentencesCurrentVersion;
			}

			const guide = this._getGuideById(sentenceSource)
			return guide.GuideSentences;
		}

		return [];
	}

	_getSelectedSentencesSourceName = () => {
		const {sentenceSource, isArticle} = this.state;

		if (sentenceSource && !isArticle) {
			const guide = this._getGuideById(sentenceSource)
			return guide.Language + " manuscript";
		}

		return "Article";
	}

	_getSelectedSentencesLanguage = () => {
		const {sentenceSource, isArticle} = this.state;

		if (sentenceSource && !isArticle) {
			const guide = this._getGuideById(sentenceSource)
			return guide.Language.toLowerCase();
		}

		return "";
	}

	_setFirstSentence = (key) => {
		const {action, article} = this.props;

		if (key && key !== article?.Id) {
			const guide = this._getGuideById(key);
			return action.selectFirstRelevantGuideSentence(guide);
		}

		action.selectFirstRelevantSentence();
	}

	_getGuidesForDropdown = () => {
		const {article} = this.props;

		if (!article?.Guides) return [];

		return article.Guides.filter((guide) =>
			guide.State >= constants.GUIDE_STATE.Recording
			&& guide.State !== constants.GUIDE_STATE.Disabled
		)
	}

	_getSentenceSourceEntity = () => {
		const {sentenceSource, isArticle} = this.state;
		const {article} = this.props;
		if (sentenceSource && !isArticle) {
			return this._getGuideById(sentenceSource);
		}

		return article;
	}

	GetMarker = () => {
		return this._marker;
	};

	_shouldShowSentencesSourceDropdown() {
		const {article, loading, user} = this.props;
		const {tabPosition} = this.state

		const guides = this._getGuidesForDropdown();
		if (tabPosition !== sentencesTabState) return false;
		if (loading.articleLoading) return false;
		if (!article?.GuidesEnabled) return false;

		if (isAdmin(user) && guides.length > 0) return true;

		return guides.length > 1;
	}

	render() {
		const { article, action, sentence, player, user } = this.props;
		const {tabPosition, showPopconfirm, sentenceSource, isArticle, isLoading} = this.state

		const articleHasUpdatedRecordings = this._articleHasUpdatedRecordings();

		const sentences = this._getSentences();
		const guides = this._getGuidesForDropdown();

		const dropdown = (
			<Menu
				onClick={(e) => {
					return this._setSentencesSource(e.key);
				}}
			>
				{isAdmin(user) && (<Menu.Item key={article.Id}>Article</Menu.Item>)}
				{guides.length > 0 &&
				guides.map((g, i) => (
					<Menu.Item key={g.Id}>{g.Language} manuscript</Menu.Item>
				))}
			</Menu>);

		let title = "Sentences";
		switch (article.State) {
			case constants.ARTICLE_STATE.Recording:
			case constants.ARTICLE_STATE.RecordingChanges:
				title = "Sentences to record";
				break;
			case constants.ARTICLE_STATE.ProofListening:
				title = "Sentences to proof listen";
				break;
			case constants.ARTICLE_STATE.CorrectionsNeeded:
				title = "Sentences to correct";
				break;
			default:
				title = "Sentences";
		}

		return (
			<div className="height-100">
				<RndWrapper
					ref={(c) => {
						window.sentencesRnd = c;
					}}
					isVisible={this._shouldShowSentenceList()}
					defaultWidth={400}
					defaultHeight={Math.max(window.innerHeight / 2, 100)}
					minHeight={200}
					minWidth={340}
					maxHeight={900}
					maxWidth={700}
					left={0}
					top={50}
					positionAsPercentage
					className="sentences"
					title={title}
					enableResizing
					header={
						<div className="height-100">
							{this._canToggleSentence() && tabPosition !== guidesTabState && (
								<div style={{ float: "left" }}>
									<Tooltip
										content={
											sentence.SentenceState ===
											constants.SENTENCE_STATE.Disabled
												? `Enable sentence ${constants.SENTENCE_KEYS.EnableDisableSentence.text}`
												: `Disable sentence ${constants.SENTENCE_KEYS.EnableDisableSentence.text}`
										}
										direction="up"
									>
										<Toggle
											toggleAction={() => action.toggleDeactivated()}
											checked={
												sentence.SentenceState !==
												constants.SENTENCE_STATE.Disabled
											}
										/>
									</Tooltip>
								</div>
							)}
							<div style={{ float: "right" }}>
								<Tooltip content={`Sign out ${user.name}`} direction="up">
									<LogoutButton
										type="button"
										className="logout-button"
										onClick={action.logout}
									/>
								</Tooltip>
							</div>
						</div>
					}
					tabs={isAdmin(user) ? (
						<Radio.Group
							value={tabPosition}
							onChange={this._changeTabPosition}
							optionType="button"
							buttonStyle="solid"
						>
							<Radio.Button value={sentencesTabState}>Sentences</Radio.Button>
							<Radio.Button value={guidesTabState}>Guides</Radio.Button>
						</Radio.Group>
					) : (<div />)}
					content={
						<div className="height-100">
							{
								this._shouldShowSentencesSourceDropdown()
							&&
							(
								<div className="sentences-source-holder">
									<Dropdown overlay={dropdown}>
										<button
											type="button"
											className="ant-dropdown-link"
											onClick={(e) => e.preventDefault()}
										>
											{this._getSelectedSentencesSourceName()}<DownOutlined />
										</button>
									</Dropdown>
									<p>Sentence source:</p>
								</div>
							)}
							{isLoading && <Loader />}
							{tabPosition === sentencesTabState
							&& article?.Id
							&& sentences?.length === 0
							&& !isArticle
							&& this._getIsArticleChangedSinceGuideManuscripting()
							&& !isLoading && (
								<div className="sentences-info">Article got some changes. Please update the manuscript
									content or confirm no changes for the exist one.
								</div>
							)}
							{tabPosition === sentencesTabState
							&& (!article?.Id || sentences?.length === 0)
							&& isArticle
							&& !isLoading && (
								<div className="sentences-info">No sentences to display</div>
							)}
							{tabPosition === sentencesTabState && article?.Id && sentences?.length > 0 && (
								<div className={"sentenceTable " + this._getSelectedSentencesLanguage()} id="navigation-scroll">
									<table>
										<tbody>
											{sentences?.map((s) => {
												const sentenceActiveClass =
													sentence.Order === s.Order
														? "sentence-active"
														: "sentence";
												const sentenceRecordedClass =
													s.SentenceState === constants.SENTENCE_STATE.Recorded
														? "sentence-recorded"
														: "sentence";
												const sentenceDisabledClass =
													s.SentenceState === constants.SENTENCE_STATE.Disabled
														? "sentence-disabled"
														: "sentence";
												const sentenceChangedClass =
													s.SentenceState === constants.SENTENCE_STATE.Changed
														? "sentence-changed"
														: "sentence";
												const sentenceRemarkedClass = util.isEmptyOrSpaces(
													s.Remark
												)
													? "sentence"
													: "sentence-remark";

												const isRecordingProcessed =
													player?.recordings[s.RecordingId]?.IsProcessed;

												const exclamationMessages = [];

												if (!util.isEmptyOrSpaces(s.Remark)) {
													exclamationMessages.push(
														<div key={Math.random()}>{s.Remark}</div>
													);
												}
												if (
													s.SentenceState === constants.SENTENCE_STATE.Changed
												) {
													exclamationMessages.push(
														<div key={Math.random()}>
															This sentence has been changed in the article and
															needs to be recorded again
														</div>
													);
												}
												if (
													articleHasUpdatedRecordings &&
													!isRecordingProcessed &&
													s.SentenceState === constants.SENTENCE_STATE.Recorded
												) {
													exclamationMessages.push(
														<div key={Math.random()}>
															This sentence has been re-recorded and needs to be
															proof listened again
														</div>
													);
												}

												return (
													<tr key={"s-" + s.Order}>
														<td>
															<div
																className={classNames(
																	sentenceActiveClass,
																	sentenceRecordedClass,
																	sentenceDisabledClass,
																	sentenceChangedClass,
																	sentenceRemarkedClass
																)}
																id={s.Order}
																onClick={() => this._onSentenceClick(s)}
															>
																{s.Content}
															</div>
														</td>
														<td>
															{exclamationMessages.length > 0 && (
																<ExclamationIcon
																	message={exclamationMessages}
																/>
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							)}
							{tabPosition === guidesTabState && article?.Id && !isLoading && (
								<div className="guidesEnabling">

									<Tooltip
										content={
											article?.GuidesEnabled
												? `Disable guides`
												: `Enable guides`
										}
										direction="up"
									>
										<p>{
											article?.GuidesEnabled
												? `Disable Begreppa guides:`
												: `Enable Begreppa guides:`
										}
										</p>
										<Popconfirm
											title="Do you really want to disable all Article guides?"
											onConfirm={() => (action.toggleEnabledGuides() && this.setState({showPopconfirm: false}))}
											onCancel={() => this.setState({showPopconfirm:false})}
											visible={showPopconfirm}
											overlayStyle={{zIndex: "19000"}}
										>
											<Toggle
												toggleAction={() => this._toggleEnabledGuides(article?.GuidesEnabled)}
												checked={
													article?.GuidesEnabled
												}
											/>
										</Popconfirm>
									</Tooltip>

								</div>
							)}
						</div>
					}
					footer={
						article?.Id && this._shouldShowSentenceFooter() ? (
							<div className="height-100">
								<SentencesFooter
									sentenceSource={sentenceSource}
									isArticle={isArticle}
								/>
							</div>
						) : null
					}
				/>
			</div>
		);
	}
}

SentencesRnd.propTypes = {
	article: PropTypes.object.isRequired,
	sentence: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	loading: PropTypes.object.isRequired,
	player: PropTypes.object,
	action: PropTypes.shape({
		getUser: PropTypes.func.isRequired,
		setSelectedSentence: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired,
		saveRemark: PropTypes.func.isRequired,
		toggleEnabledGuides: PropTypes.func.isRequired,
		fetchGuide: PropTypes.func.isRequired,
		selectFirstRelevantSentence: PropTypes.func.isRequired,
		selectFirstRelevantGuideSentence: PropTypes.func.isRequired,
		getGuideRecordings: PropTypes.func.isRequired,
		getRecordings: PropTypes.func.isRequired,
	}).isRequired
};

export default connector(SentencesRnd, (props) => ({
	article: props.article,
	sentence: props.sentence,
	user: props.user,
	player: props.player,
	loading: props.loading,
	action: {
		getUser: props.action.getUser,
		setSelectedSentence: props.action.setSelectedSentence,
		logout: props.action.logout,
		toggleDeactivated: props.action.toggleDeactivated,
		saveRemark: props.action.saveRemark,
		setArticleDone: props.action.setArticleDone,
		toggleEnabledGuides: props.action.toggleEnabledGuides,
		fetchGuide: props.action.fetchGuide,
		selectFirstRelevantSentence: props.action.selectFirstRelevantSentence,
		selectFirstRelevantGuideSentence: props.action.selectFirstRelevantGuideSentence,
		getGuideRecordings: props.action.getGuideRecordings,
		getRecordings: props.action.getRecordings,
	}
}));
