import * as constants from "../../constants";

export const showLoader = source => {
	// logger.debug("showLoader", source);
	return async (dispatch, getState) => {
		const { player } = getState();
		const loader = document.getElementsByClassName("player-loader")[0];
		if (loader && player?.status !== constants.PLAYER_STATUS.Recording) {
			loader.classList.add("source-" + source);
			const loaderClasses = [...loader.classList].filter(val =>
				val.startsWith("source-")
			).length;
			if (loaderClasses > 0) {
				loader.style.display = "block";
			}
		}
	};
};
export const hideLoader = source => {
	// logger.debug("hideLoader", source);
	return async (dispatch, getState) => {
		const loader = document.getElementsByClassName("player-loader")[0];
		if (loader) {
			loader.classList.remove("source-" + source);
			const loaderClasses = [...loader.classList].filter(val =>
				val.startsWith("source-")
			).length;
			if (loaderClasses <= 0) {
				loader.style.display = "none";
			}
		}
	};
};
