import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function PrevButton(props) {
	const { selectedSentence, autoDisable, onClick } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-backward button", {
				"button--disabled": selectedSentence.foundIndex === 0 && autoDisable
			})}
			aria-label="Previous"
			onClick={onClick}
		/>
	);
}

PrevButton.propTypes = {
	selectedSentence: PropTypes.shape({
		id: PropTypes.number,
		index: PropTypes.number,
		foundIndex: PropTypes.number
	}),
	autoDisable: PropTypes.bool,
	onClick: PropTypes.func
};

PrevButton.defaultProps = {
	selectedSentence: { id: 0, index: 0, foundIndex: 0 },
	autoDisable: false,
	onClick: () => {}
};
