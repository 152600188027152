import { Auth } from "aws-amplify";
import errorHandler from "./errorHandler";
import * as logger from "../../helpers/logger";

export const getUser = (cb) => {
	return async (dispatch) => {
		return Auth.currentSession().then((data) => {
			if (errorHandler(dispatch, data)) return Promise.reject(data.errors);

			const result = {
				email: data.getIdToken().payload.email,
				groups: data.getIdToken().payload["cognito:groups"],
				name: data.getIdToken().payload["cognito:username"]
			};

			dispatch({
				type: "SET_USER",
				param: "user",
				data: result
			});

			if (typeof cb === "function") cb(result);
			return result;
		});
	};
};

export const logout = (cb) => {
	return async (dispatch) => {
		dispatch({
			type: "LOGOUT_REQUEST"
		});
		return Auth.signOut()
			.then((data) => {
				if (errorHandler(dispatch, data)) throw new Error("Failed to sign out");

				dispatch({
					type: "LOGOUT"
				});
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to sign out");
			});
	};
};

export const clearData = (state) => {
	return (dispatch) => {
		dispatch({
			type: "CLEAR_DATA",
			state
		});
	};
};
