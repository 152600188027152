/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import "./index.css";

export default function LogoutButton(props) {
	const { className } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("button fa fa-sign-out-alt", className)}
			aria-label="Log out"
			onClick={() => props.onClick()}
		/>
	);
}
