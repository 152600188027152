import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.css";

export default function StopRecordingButton(props) {
	const { onClick } = props;
	return (
		<div
			role="button"
			tabIndex="0"
			className={classNames("fa fa-square button")}
			onClick={onClick}
			data-tooltip="(S)"
			aria-label="Stop"
		/>
	);
}

StopRecordingButton.propTypes = {
	onClick: PropTypes.func
};

StopRecordingButton.defaultProps = {
	onClick: () => {}
};
